import { defaultTheme } from '@/themes';
import { Grow, IconButton, InputBase } from '@mui/material';
import Search from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';
import { useRouter } from 'next/router';
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import {
  StyledDesktopSearchField,
  StyledDesktopSearchForm,
  StyledMobileSearchFieldPaper,
} from './searchbar-style';

export const Searchbar = ({ isMobile }: { isMobile: boolean }) => {
  const [borderColor, setBorderColor] = useState<string>('#e5e5e5');
  const [visible, setVisibility] = useState<boolean>(false);
  const router = useRouter();
  const [query, setQuery] = useState('');
  const textFieldRef = useRef<HTMLInputElement>();
  const searchFormRef = useRef<HTMLFormElement>(null);
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };
  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (query.trim()) {
      router.push(`/search/${query}`);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        visible &&
        searchFormRef.current &&
        !searchFormRef.current.contains(event.target as Node)
      ) {
        setVisibility(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [visible]);

  if (isMobile) {
    return (
      <StyledMobileSearchFieldPaper
        // className={classes.mobileSearchField}
        component="form"
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: '100%',
        }}
        onSubmit={handleOnSubmit}
      >
        <IconButton
          type="button"
          sx={{ p: '10px', pl: 0, borderBottomColor: borderColor }}
          aria-label="search"
          className="search-icon"
        >
          <Search fontSize="small" />
        </IconButton>
        <InputBase
          type="search"
          sx={{ ml: 1, flex: 1, borderBottomColor: borderColor }}
          placeholder="Search"
          onFocus={() =>
            setBorderColor(defaultTheme.palette.primary.main ?? '')
          }
          onBlur={() => setBorderColor(defaultTheme.palette.primary.main ?? '')}
          inputRef={textFieldRef}
          onChange={handleOnChange}
          className="search-input"
        />
      </StyledMobileSearchFieldPaper>
    );
  }

  return (
    <StyledDesktopSearchForm
      // className={classes.desktopSearchForm}
      ref={searchFormRef}
      component="form"
      onSubmit={handleOnSubmit}
    >
      <IconButton
        size="large"
        color="inherit"
        sx={{ p: 0, mr: 2 }}
        onClick={() => setVisibility(!visible)}
      >
        {visible ? <Close /> : <Search />}
      </IconButton>
      <Grow in={visible} style={{ transformOrigin: '0 0 0' }}>
        <StyledDesktopSearchField
          type="search"
          // className={classes.searchField}
          placeholder="Search"
          inputRef={textFieldRef}
          onChange={handleOnChange}
        />
      </Grow>
    </StyledDesktopSearchForm>
  );
};
