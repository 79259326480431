export default function endMParticleMediaSession() {
  if (!(window as any).beasleyanalytics) {
    return;
  }

  if (
    (window as any).mediaSession &&
    !(window as any).mediaSession?.isStopped
  ) {
    (window as any).mediaSession.isStopped = true;

    (window as any).beasleyanalytics.setMParticlePerEventKeys();
    const contentEndOptions: any = {};
    contentEndOptions.customAttributes = (
      window as any
    ).beasleyanalytics.getMParticleMediaEventObject(
      (window as any).beasleyanalytics.BeasleyAnalyticsMParticleProvider
        .mparticleEventNames.mediaContentEnd
    );
    (window as any).mediaSession.logMediaContentEnd(contentEndOptions);

    (window as any).beasleyanalytics.setMParticlePerEventKeys();
    const sessionEndOptions: any = {};
    sessionEndOptions.customAttributes = (
      window as any
    ).beasleyanalytics.getMParticleMediaEventObject(
      (window as any).beasleyanalytics.BeasleyAnalyticsMParticleProvider
        .mparticleEventNames.mediaSessionEnd
    );

    // Directly Add media_time_spent to Media Session End event.
    sessionEndOptions.customAttributes.media_time_spent = (
      window as any
    ).mediaSession.mediaTimeSpent();

    (window as any).mediaSession.logMediaSessionEnd(sessionEndOptions);
  }
}
