/**
 * Used to interact with the LyticsTrackAudio window object
 * which is provided by the GTM implementation.
 *
 * @param {String} action The action to take (ie. play, pause, end)
 * @param {Object} params Set of parameters
 */
export default function lyticsTrack(action: string, params: any) {
  // Check for googletag
  if ((window as any).googletag && (window as any).googletag.cmd) {
    // Push to the CMD queue
    (window as any).googletag.cmd.push(() => {
      // Abandon if no LyticsTrackAudio global
      if (typeof (window as any).LyticsTrackAudio === 'undefined') {
        return;
      }

      // If action play
      if (
        action === 'play' &&
        (window as any).LyticsTrackAudio.set_podcastPayload
      ) {
        (window as any).LyticsTrackAudio.set_podcastPayload(
          {
            type: 'podcast',
            name: params.artistName,
            episode: params.cueTitle,
          },
          () => {
            (window as any).LyticsTrackAudio.playPodcast();
          }
        );
      }

      // If action pause
      if (action === 'pause' && (window as any).LyticsTrackAudio.pausePodcast) {
        (window as any).LyticsTrackAudio.pausePodcast();
      }

      // If action end
      if (action === 'end' && (window as any).LyticsTrackAudio.endOfPodcast) {
        (window as any).LyticsTrackAudio.endOfPodcast();
      }
    });
  }
}
