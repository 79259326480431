import { Box, SxProps } from '@mui/material';
import Blocks from '@orgnc/core/components/blocks/Blocks';
import { RenderBlockProps } from '@orgnc/core/components/blocks/Blocks/helpers/RenderBlock';

const CoreGroupBlock: React.FunctionComponent<RenderBlockProps> = ({
  block,
}) => {
  const { innerBlocks, attributes } = block;
  const {
    // anchor,
    // ariaLabel,
    className,
    columns_desktop: columnsDesktop,
    columns_mobile: columnsMobile,
    columns_tablet: columnsTablet,
    gap_desktop: gapDesktop,
    gap_mobile: gapMobile,
    gap_tablet: gapTablet,
    layout: { type, flexWrap, orientation },
    tagName = 'div',
  } = attributes;

  console.log({ block });

  // const type = 'grid';
  const sxClasses: SxProps = {
    display: type,
    flexWrap,
    flexDirection: orientation === 'vertical' ? 'column' : 'row',
    gap: {
      xs: `${gapMobile}px`,
      sm: `${gapTablet}px`,
      md: `${gapDesktop}px`,
    },
    gridTemplateColumns: {
      xs: `repeat(${columnsMobile},minmax(0,1fr))`,
      sm: `repeat(${columnsTablet},minmax(0,1fr))`,
      md: `repeat(${columnsDesktop},minmax(0,1fr))`,
    },
  };

  // if (type === 'grid') {
  //   sxClasses = {
  //     ...sxClasses,
  //   };
  // }

  return (
    <Box className={className} component={tagName} sx={sxClasses}>
      <Blocks blocks={innerBlocks} />
    </Box>
  );
};

export default CoreGroupBlock;
