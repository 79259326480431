import htlSettingsQuery from '@/lib/queries/htlSettingsQuery';
import { useResolveUrl } from '@orgnc/core/hooks';
import useGlobalQuery from '@orgnc/core/hooks/useGlobalQuery';
import { INode } from '@orgnc/core/lib/wordpress/api/queries/resolveUrlQuery';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { ReactElement, useEffect, useRef } from 'react';

type WindowAds = Window & {
  opscobid?: {
    cmd?: Array<() => void>;
    setTargeting?: (key: string, value: string) => void;
    layout?: () => void;
  };
};
declare let window: WindowAds;

/**
 * HashtagLabs component integrates external ad scripts and manages ad targeting parameters.
 *
 * It preloads required CSS and JS resources based on environment settings,
 * and updates the ad targeting ('cpage') on route changes.
 *
 * @component
 * @returns {ReactElement|null} The ad component rendering scripts or null based on query.
 */

interface INodeWithCategories {
  node?: INode & {
    databaseId?: number;
    categories?: { nodes: { name: string }[] };
  };
}

interface IHashtagLabsProps {
  databaseId?: number;
  categories?: { nodes: { name: string }[] };
}
const HashtagLabs = (props?: IHashtagLabsProps): ReactElement | null => {
  const router = useRouter();
  const { data: htlSettings } = useGlobalQuery(htlSettingsQuery);
  const { node } = useResolveUrl() as INodeWithCategories;
  const databaseId = props?.databaseId ?? node?.databaseId ?? '';
  const categoryNodes =
    props?.categories?.nodes ?? node?.categories?.nodes ?? [];
  const categoryNames = categoryNodes.map((cat) => cat.name).join(',');
  const scriptRef = useRef<HTMLDivElement>(null);

  const isProduction = Boolean(
    htlSettings?.acfOptionsGeneral?.generalOptions?.htlAds?.isProduction
  );
  const market =
    htlSettings?.acfOptionsGeneral?.generalOptions?.htlAds?.market || '';
  const genre =
    htlSettings?.acfOptionsGeneral?.generalOptions?.htlAds?.genre || '';
  const ctest =
    htlSettings?.acfOptionsGeneral?.generalOptions?.htlAds?.ctest || '';

  const cssPath = isProduction
    ? 'https://htlbid.com/v3/beaselymediagroup.com/opscobid.css'
    : 'https://htlbid.com/stage/v3/beaselymediagroup.com/opscobid.css';
  const jsPath = isProduction
    ? 'https://htlbid.com/v3/beaselymediagroup.com/opscobid.js'
    : 'https://htlbid.com/stage/v3/beaselymediagroup.com/opscobid.js';

  // Function to generate the script content with current values
  // const generateScriptContent = () => {
  //   return `
  //     window.opscobid = window.opscobid || {};
  //     opscobid.cmd = opscobid.cmd || [];
  //     opscobid.cmd.push(function() {
  //       opscobid.setTargeting('is_testing', ${isProduction ? "'no'" : "'yes'"});
  //       opscobid.setTargeting('cdomain', window.location.hostname);
  //       opscobid.setTargeting('cpage', window.location.pathname === '/' ? 'home' : window.location.pathname);
  //       opscobid.setTargeting('ctest', '${ctest}');
  //       opscobid.setTargeting('genre', '${genre}');
  //       opscobid.setTargeting('market', '${market}');
  //       opscobid.setTargeting('cpostid', '${databaseId}');
  //       opscobid.setTargeting('categories', '${categoryNames}');
  //       opscobid.layout();
  //     });
  //   `;
  // };

  // Effect to handle CSS preloading
  useEffect(() => {
    // Create a link element for preloading the CSS
    const link = document.createElement('link');

    link.rel = 'preload'; // Start as preload
    link.href = cssPath;
    link.as = 'style';
    link.type = 'text/css';

    link.onload = () => {
      // Switch to stylesheet after loading
      link.rel = 'stylesheet';
    };

    // Append the link to the document head
    document.head.appendChild(link);

    return () => {
      // Cleanup on component unmount
      document.head.removeChild(link);
    };
  }, [cssPath]);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { _noext } = router.query;

  // Effect to inject and execute script on initial render and route changes
  useEffect(() => {
    if (!scriptRef.current) return;

    // Function to generate the script content with current values
    const generateScriptContent = () => {
      const escapedCategoryNames = categoryNames.replace(/'/g, "\\'");

      return `
        window.opscobid = window.opscobid || {};
        opscobid.cmd = opscobid.cmd || [];
        opscobid.cmd.push(function() {
          opscobid.setTargeting('is_testing', ${
            isProduction ? "'no'" : "'yes'"
          });
          opscobid.setTargeting('cdomain', window.location.hostname);
          opscobid.setTargeting('cpage', window.location.pathname === '/' ? 'home' : window.location.pathname);
          opscobid.setTargeting('ctest', '${ctest}');
          opscobid.setTargeting('genre', '${genre}');
          opscobid.setTargeting('market', '${market}');
          opscobid.setTargeting('cpostid', '${databaseId}');
          opscobid.setTargeting('categories', '${escapedCategoryNames}');
          opscobid.layout();
        });
      `;
    };

    // Clear previous script content
    while (scriptRef.current.firstChild) {
      scriptRef.current.removeChild(scriptRef.current.firstChild);
    }

    // Create new script element
    const scriptElement = document.createElement('script');
    scriptElement.id = 'opscobid-init';
    scriptElement.textContent = generateScriptContent();

    // Append new script element to the container
    scriptRef.current.appendChild(scriptElement);

    // Execute the script immediately
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    const executeScript = new Function(scriptElement.textContent);
    executeScript();
  }, [
    router.asPath,
    isProduction,
    ctest,
    genre,
    market,
    databaseId,
    categoryNames,
  ]);

  // Effect to handle route changes using the opscobid API directly
  useEffect(() => {
    const handleRouteChange = () => {
      window.opscobid = window.opscobid || {};
      window.opscobid.cmd = window.opscobid.cmd || [];
      window.opscobid.cmd.push(() => {
        window.opscobid!.setTargeting!(
          'cpage',
          window.location.pathname === '/' ? 'home' : window.location.pathname
        );
        window.opscobid!.setTargeting!('cpostid', databaseId.toString());
        window.opscobid!.setTargeting!('categories', categoryNames);
      });
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, categoryNames, databaseId]);

  // Return null if the _noext query parameter is present
  if (_noext !== undefined) {
    return null;
  }

  return (
    <>
      <Script src={jsPath} strategy="lazyOnload" />
      <div ref={scriptRef} id="opscobid-script-container"></div>
    </>
  );
};

export default HashtagLabs;
