type TsSdk = any;

const SCRIPT_ID = 'td-sdk';
const SCRIPT_PATH = '/js/td-sdk.min.js';

export function loadTdSk(): Promise<TsSdk> {
  return new Promise((resolve, reject) => {
    const elem = document.getElementById(SCRIPT_ID);

    if (elem !== null) {
      resolve(true);
      return;
    }

    const script = document.createElement('script');
    script.src = SCRIPT_PATH;
    script.id = SCRIPT_ID;
    script.async = true;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = (event) => {
      reject(event);
    };
    document.body.appendChild(script);
  });
}
