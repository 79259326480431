import { styled } from '@mui/material';
import Image from 'next/image';

export const StyledLogoImage = styled(Image)(({ theme }) => ({
  '&.main-logo': {
    // maxWidth: '120px',
    width: 'auto',
    height: '69px !important',
  },

  '&.default-image': {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    '&.main-logo': {
      // marginRight: '20px',
      marginLeft: '0',
      // maxWidth: '118px',
      width: 'auto',
      height: '67px !important',
    },
    '&.default-image': {
      display: 'inline-block',
      // maxWidth: '142px',
      width: 'auto',
      height: '47px !important',
    },
  },
}));
