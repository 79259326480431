import { Box } from '@mui/material';

const NewsletterPlaceholder = () => (
  <Box
    id="nsf-container-0"
    className="nsf-container"
    sx={{
      maxWidth: 700,
      width: '100%',
    }}
  />
);

export default NewsletterPlaceholder;
