import { NextPageContext } from 'next';

export const getStatusCode = (ctx: NextPageContext) => {
  if (ctx.res) {
    return ctx.res.statusCode;
  }

  const errCode = ctx.err?.statusCode;
  return errCode ?? 404;
};
