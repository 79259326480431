import { RenderBlockProps } from '@orgnc/core/components/blocks/Blocks/helpers/RenderBlock';
import Script from 'next/script';
import { type ComponentType, type PropsWithChildren } from 'react';

const FacebookEmbedBlock: ComponentType<PropsWithChildren<RenderBlockProps>> = (
  props
) => {
  const { block } = props;

  return (
    <>
      <Script
        src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v21.0"
        strategy="lazyOnload"
        crossOrigin="anonymous"
      />
      <Script id="facebook-embed-script" strategy="afterInteractive">
        {`function debounce(func, wait) {
          let timeout;
          return function() {
              clearTimeout(timeout);
              timeout = setTimeout(func, wait);
          };
        }

        function refreshFacebookEmbed() {
          if (typeof FB !== 'undefined') {
            FB.XFBML.parse();
          }
        }

        window.addEventListener('resize', debounce(refreshFacebookEmbed, 300));`}
      </Script>
      <div id="fb-root"></div>
      <div className="refact-fb-embed-block">
        <div
          className="fb-post fb-desktop-only"
          data-href={block.attributes.fbUrl}
          data-show-text="false"
        ></div>
        <a
          className="fb-mobile-only"
          href={block.attributes.fbUrl}
          target="_blank"
        >
          View the post on Facebook
        </a>
      </div>
    </>
  );
};

export default FacebookEmbedBlock;
