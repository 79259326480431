import React, { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import * as actions from '@/redux/actions/player';
import sound from '@/../public/images/sound.png';
import { RootState } from '@/redux/store';

interface FeatureLayoutVolumeProps {
  volume: number;
  audio?: string;
  setVolume: (value: number) => void;
}

interface FeatureLayoutVolumeState {
  showVolume: boolean;
}
class FeatureLayoutVolume extends PureComponent<
  FeatureLayoutVolumeProps,
  FeatureLayoutVolumeState
> {
  private volumeRef = createRef<HTMLDivElement>();

  constructor(props: any) {
    super(props);

    this.state = { showVolume: false };

    this.toggleVolumeSlider = this.toggleVolumeSlider.bind(this);
    this.handleUserEventOutside = this.handleUserEventOutside.bind(this);
    this.handleEscapeKeyDown = this.handleEscapeKeyDown.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleUserEventOutside, false);
    document.addEventListener('keydown', this.handleEscapeKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener(
      'mousedown',
      this.handleUserEventOutside,
      false
    );
    document.removeEventListener('keydown', this.handleEscapeKeyDown, false);
  }

  toggleVolumeSlider() {
    this.setState((prevState) => ({ showVolume: !prevState.showVolume }));
  }

  handleUserEventOutside(e: MouseEvent) {
    const ref = this.volumeRef.current;
    if (ref && !ref.contains(e.target as Node)) {
      this.setState({ showVolume: false });
    }
  }

  handleEscapeKeyDown(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      this.setState({ showVolume: false });
    }
  }

  render() {
    const { audio, setVolume } = this.props;
    const { showVolume } = this.state;
    let { volume } = this.props;

    if (audio && audio.startsWith('https://omny.fm/')) {
      return null;
    }

    // Max volume is 82
    if (volume > 82) {
      volume = 82;
    }

    // Thumb is 10px so adjust prebar width
    let prebarWidth = volume - volume * (10 / 82);

    // Min prebarWidth is 0
    if (prebarWidth < 0) {
      prebarWidth = 0;
    }

    return (
      <div ref={this.volumeRef} className="music_icon sound_icon">
        <button
          onClick={this.toggleVolumeSlider}
          aria-label="Toggle volume controls"
          type="button"
          style={{
            background: 'none',
            border: 'none',
            padding: 0,
            textDecoration: 'underline',
            color: 'blue',
            cursor: 'pointer',
          }}
        >
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={sound.src} alt="" />
        </button>
        <div className="ee-range-input">
          <div
            className={`ee-range-input-slider ${
              showVolume ? '-volume-visible' : ''
            }`}
            aria-hidden={!showVolume}
          >
            <div className="new-test-range-input">
              <input
                type="range"
                min="0"
                max="82"
                step="1"
                value={volume}
                onChange={(e) => setVolume(Number(e.target.value))}
              />
            </div>
            <p className="pre-bar" style={{ width: `${prebarWidth}px` }} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ player }: RootState) => ({
  volume: player.volume,
  audio: player.audio,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ setVolume: actions.setVolume }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeatureLayoutVolume);
