import { getDefaultTheme } from '../default-theme/default-theme';

export default getDefaultTheme({
  color: {
    primary: '#DB3734',
    secondary: '#000000',
    error: '#DB3734',
    brand: {
      main: '#193C68',
      text: '#FFF5D8',
    },
  },
});
