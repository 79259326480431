// import MediaSession from '@mparticle/web-media-sdk';
// import mParticle from '@mparticle/web-sdk';
import endMParticleMediaSession from './endMParticleMediaSession';

export default function sendMParticlePlayMediaEvent() {
  if (!(window as any).beasleyanalytics) {
    return;
  }

  const streamParams = (
    window as any
  ).beasleyanalytics.getMParticleAllMediaFields();
  if (!streamParams) {
    return;
  }

  // (window as any).mediaSession = new MediaSession(
  // 	mParticle, // mParticle SDK Instance
  // 	streamParams.content_id, // Custom media ID, added as content_id for media events
  // 	streamParams.content_title, // Custom media Title, added as content_title for media events
  // 	streamParams.content_duration, // Duration in milliseconds, added as content_duration for media events
  // 	streamParams.content_type, // Content Type (Video or Audio), added as content_type for media events
  // 	streamParams.stream_type, // Stream Type (OnDemand or LiveStream), added as stream_type for media events
  // 	true, // Log Page Event Toggle (true/false)
  // 	true, // Log Media Event Toggle (true/false)
  // );
  (window as any).mediaSession.isStopped = false;

  (window as any).removeEventListener('beforeunload', endMParticleMediaSession);
  (window as any).addEventListener('beforeunload', endMParticleMediaSession);

  (window as any).beasleyanalytics.setMParticlePerEventKeys();
  const sessionStartOptions: any = {};
  sessionStartOptions.customAttributes = (
    window as any
  ).beasleyanalytics.getMParticleMediaEventObject(
    (window as any).beasleyanalytics.BeasleyAnalyticsMParticleProvider
      .mparticleEventNames.mediaSessionStart
  );
  (window as any).mediaSession.logMediaSessionStart(sessionStartOptions);

  (window as any).beasleyanalytics.setMParticlePerEventKeys();
  const playOptions: any = {};
  playOptions.customAttributes = (
    window as any
  ).beasleyanalytics.getMParticleMediaEventObject(
    (window as any).beasleyanalytics.BeasleyAnalyticsMParticleProvider
      .mparticleEventNames.play
  );
  (window as any).mediaSession.logPlay(playOptions);
}
