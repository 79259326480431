import livePlayerLocalStorage from './livePlayerLocalStorage';

/**
 * Returns a matching stream if local storage
 * station value matches the stream.stream_call_letters
 *
 * @param {Array} streamsList Array of streams
 * @returns {String|Undefined} First match || undefined
 */
export default function getInitialStation(streamsList: any) {
  const station = livePlayerLocalStorage.getItem('station');
  if (typeof streamsList.pause === 'function') {
    return streamsList.find(
      (stream: any) => stream.stream_call_letters === station
    );
  }
  return '';
}
