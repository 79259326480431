/* eslint-disable */
import htlSettingsQuery from '@/lib/queries/htlSettingsQuery';
import useGlobalQuery from '@orgnc/core/hooks/useGlobalQuery';
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';

// Define window interface for TypeScript
type WindowWithBBGi = Window & {
  BBGi?: {
    Newsletter?: {
      init?: () => void;
      destroy?: () => void;
    };
  };
};
declare let window: WindowWithBBGi;

const NewsletterScript: React.FunctionComponent = () => {
  const { data: htlSettings } = useGlobalQuery(htlSettingsQuery);
  const router = useRouter();
  const scriptRef = useRef<HTMLDivElement>(null);
  const siteId =
    htlSettings?.acfOptionsGeneral?.generalOptions?.htlAds?.siteId ?? 'WBCN';

  // Effect to load the script on initial render
  useEffect(() => {
    if (!scriptRef.current) return;

    // Create script element
    const scriptElement = document.createElement('script');
    scriptElement.src = `https://newsletters.bbgisites.com/launcher/v2/launcher-${siteId}.min.js`;
    scriptElement.async = true;

    // Add onload handler
    scriptElement.onload = () => {
      initNewsletter();
    };

    // Append script to the container
    scriptRef.current.appendChild(scriptElement);

    // Cleanup function
    return () => {
      if (scriptRef.current && scriptRef.current.contains(scriptElement)) {
        scriptRef.current.removeChild(scriptElement);
      }
    };
  }, [siteId]);

  // Function to initialize the newsletter
  const initNewsletter = () => {
    if (
      window.BBGi &&
      window.BBGi.Newsletter &&
      typeof window.BBGi.Newsletter.init === 'function'
    ) {
      // Force re-initialization by first cleaning up any existing instances
      if (typeof window.BBGi.Newsletter.destroy === 'function') {
        window.BBGi.Newsletter.destroy();
      }

      // Re-initialize the newsletter
      window.BBGi.Newsletter.init();
    } else {
      console.log('BBGi.Newsletter not available yet');
    }
  };

  // Effect to handle route changes
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      // Try multiple times with increasing delays to ensure initialization
      const timers = [
        setTimeout(() => initNewsletter(), 100),
        setTimeout(() => initNewsletter(), 500),
        setTimeout(() => initNewsletter(), 1000),
      ];

      return () => timers.forEach((timer) => clearTimeout(timer));
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  return <div ref={scriptRef} id="newsletter-script-container"></div>;
};

export default NewsletterScript;
