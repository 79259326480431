import HashtagLabs from '@/components/HashtagLabs';
import HashtagLabsAd from '@/components/HashtagLabsAd';
import { Box, useTheme } from '@mui/material';

import { CenterLayout, StackLayout } from '@orgnc/core/components/primitives';
import { NewsletterSignupBlock } from '@orgnc/core/components/themes/Elements/components/blocks';
import CategoryTemplateContent from '@orgnc/core/components/themes/Elements/components/templates/CategoryTemplateContent';
import { ICategoryTemplateProps } from '@orgnc/core/lib/themes/templates/category';

const CategoryTemplate = (props: ICategoryTemplateProps) => {
  const theme = useTheme();

  return (
    <Box sx={{ pt: 5 }}>
      <HashtagLabs />
      <StackLayout space={theme.spacing(4)}>
        <CenterLayout
          maxWidth={`${theme.breakpoints.values.xl}${theme.breakpoints.unit}`}
          sx={{ width: '100%' }}
        >
          <HashtagLabsAd
            unit={'WideBanner_Top'}
            sx={{ mt: { xs: 8, sm: 2 }, height: 250 }}
          />
          <CategoryTemplateContent {...props} />
          <HashtagLabsAd unit={'WideBanner_Scroll'} />
        </CenterLayout>
        <NewsletterSignupBlock />
      </StackLayout>
    </Box>
  );
};

export default CategoryTemplate;
