import { ACTION_IS_MOBILE, ACTION_TUNER_PREROLL } from '../actions/screen';

// Define the state type
export interface ScreenState {
  isMobile: boolean;
  gamTunerPreroll: string;
}

// Default state object
export const DEFAULT_STATE: ScreenState = {
  isMobile: false,
  gamTunerPreroll: '',
};

// Reducer
// eslint-disable-next-line @typescript-eslint/default-param-last
function reducer(state: ScreenState = DEFAULT_STATE, action: any): ScreenState {
  switch (action.type) {
    case ACTION_IS_MOBILE: {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { isMobile } = action;
      return {
        ...state,
        isMobile,
      };
    }
    case ACTION_TUNER_PREROLL: {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { gamTunerPreroll } = action;
      return {
        ...state,
        gamTunerPreroll,
      };
    }
    default:
      return state;
  }
}

export default reducer;
