export const ACTION_IS_MOBILE = 'IS_MOBILE';
export const ACTION_TUNER_PREROLL = 'GAM_TUNER_PREROLL';

/**
 * isMobile action creator
 * @param {boolean} isMobile - Payload from player event
 */
export function setIsMobile(isMobile: boolean) {
  return {
    type: ACTION_IS_MOBILE,
    isMobile,
  };
}

/**
 * setTunerPreroll action creator
 * @param {string} gamTunerPreroll - Payload from event
 */
export function setTunerPreroll(gamTunerPreroll: string) {
  return {
    type: ACTION_TUNER_PREROLL,
    gamTunerPreroll,
  };
}
