import { Box, List } from '@mui/material';
import { ISocialItem } from '@orgnc/core/lib/wordpress/api/adapters/layoutDataAdapter';
import { useGlobalQuery } from '@orgnc/core/hooks';
import bmgSettingsQuery from '@/lib/queries/bmgSettingsQuery';
import { Searchbar } from '../searchbar/searchbar';
import { SocialMediaBlock } from '../social-media-block/social-media-block';
import { MyListenerController } from '../my-listener-controller/my-listener-controller';
import {
  StyledExtraListItem,
  StyledVerticalDivider,
} from './extra-items-style';

export const ExtraItems = ({ networks }: { networks: ISocialItem[] }) => {
  const { data: bmgGeneralConfiguration } = useGlobalQuery(bmgSettingsQuery);
  const listenLiveVisibility =
    bmgGeneralConfiguration?.acfOptionsGeneral?.generalOptions
      ?.bmgGeneralConfiguration?.listenLiveVisibility ?? false;
  return (
    <Box
      sx={{
        flexGrow: 0,
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'end',
      }}
    >
      <List sx={{ display: { xs: 'none', xl: 'flex' }, flexWrap: 'nowrap' }}>
        {networks.length > 0 && (
          <SocialMediaBlock networks={networks}>
            <StyledExtraListItem>
              <StyledVerticalDivider orientation="vertical" variant="inset" />
            </StyledExtraListItem>
          </SocialMediaBlock>
        )}
        <Searchbar isMobile={false} />
      </List>
      {listenLiveVisibility && <MyListenerController />}
    </Box>
  );
};
