import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import playerReducer from './reducers/player';
import screenReducer from './reducers/screen';
import rootSaga from './sagas';

// Initialize saga middleware
const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  player: playerReducer,
  screen: screenReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
    }).concat(sagaMiddleware),
});

// Run the root saga
sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
