import { getDefaultTheme } from '../default-theme/default-theme';

export default getDefaultTheme({
  color: {
    primary: '#3C6E96',
    secondary: '#000000',
    error: '#ea252a',
    brand: {
      main: '#202020',
      text: 'rgb(247, 249, 242)',
    },
  },
});
