import * as Material from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLayout } from '@orgnc/core/hooks';
import {
  INewsletter,
  ISocialItem,
} from '@orgnc/core/lib/wordpress/api/adapters/layoutDataAdapter';
import { IMenuItemNode } from '@orgnc/core/lib/wordpress/api/adapters/menuDataAdapter';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import HashtagLabsAd from '@/components/HashtagLabsAd';
import { isWhiz } from '@/utils/whiz';
import { Box } from '@mui/material';
import {
  DisablePrefetchLink,
  SocialItem,
  SocialType,
} from '@orgnc/core/components/atoms';
import OneTrust from '@orgnc/core/components/common/OrganicSDK/OneTrust';
import useAds from '@orgnc/core/hooks/useAds';
import Image from 'next/image';
import classes from './footer.module.scss';
import globalClasses from './global.module.scss';

const Footer = () => {
  const theme = useTheme();
  const { data: ads } = useAds();
  const {
    menus: { FOOTER_NAVIGATION },
    networks,
    copyright,
    newsletter,
  } = useLayout();
  const [isMobile, setIsMobile] = useState(false);
  const isMobileSSR = useSelector((state: any) => state.screen.isMobile);

  useEffect(() => {
    setIsMobile(isWhiz());
  }, []);

  if (isMobile || isMobileSSR) {
    // Return an empty footer if it's a mobile view
    return null;
  }

  const linkStyle = {
    color: theme.palette.primary.light,
  };

  return (
    <Material.Box className={classes['footer-container']}>
      <Material.Grid
        container
        rowSpacing={4}
        columnSpacing={{ sm: 4, md: 6, lg: 8 }}
      >
        <FooterLogoWrapper />

        {copyright && <CopyrightBlock copyright={copyright} />}

        {newsletter && (
          <NewsLetterBlock newsletter={newsletter} linkStyle={linkStyle} />
        )}

        {FOOTER_NAVIGATION &&
          FOOTER_NAVIGATION.length > 0 &&
          FOOTER_NAVIGATION.map((section, index) => {
            const lastSection = FOOTER_NAVIGATION.length === index + 1;

            return (
              <NavigationColumn
                section={section}
                linkStyle={linkStyle}
                _key={`navigation-group-${index + 1}`}
                key={`navigation-columns-${index}`}
              >
                {lastSection && (
                  <SocialIcons networks={networks} linkStyle={linkStyle} />
                )}
                {lastSection && ads?.organicAds.oneTrustSiteId ? (
                  <Box sx={{ my: 2 }}>
                    <OneTrust siteId={ads?.organicAds.oneTrustSiteId}>
                      Cookie Settings
                    </OneTrust>
                  </Box>
                ) : null}
              </NavigationColumn>
            );
          })}
        <HashtagLabsAd unit={'adhesion'} />
        <HashtagLabsAd unit={'interstitial'} />
      </Material.Grid>
    </Material.Box>
  );
};

const FooterLogoWrapper = () => (
  <Material.Grid item xs={12}>
    <DisablePrefetchLink href="/">
      <Material.Box sx={{ width: '200px', height: 'auto' }}>
        <Image
          className={globalClasses.imgFluid}
          title="Beasly Media Group, LLC"
          alt="Beasly Media Group, LLC"
          src="/images/beasley-dark-logo-cropped.png"
          loading="lazy"
          priority={false}
          width={200}
          height={100}
        />
      </Material.Box>
    </DisablePrefetchLink>
  </Material.Grid>
);

const CopyrightBlock = ({ copyright }: { copyright: string }) => (
  <Material.Grid item xs={12} sm={6} md={3} className={classes.download}>
    <Material.Typography
      component="div"
      className={classes.appIcons}
      dangerouslySetInnerHTML={{ __html: copyright }}
    />
  </Material.Grid>
);

const NewsLetterBlock = ({
  newsletter,
  linkStyle,
}: {
  newsletter: INewsletter;
  linkStyle: any;
}) => {
  if (
    Object.values(newsletter).some((value) => value === null || value === '')
  ) {
    return null;
  }

  return (
    <Material.Grid item xs={12} sm={6} md={3} className={classes.newsletter}>
      {newsletter.title && (
        <Material.Typography variant="h6" className={classes['sub-title']}>
          {newsletter.title}
        </Material.Typography>
      )}

      {newsletter.description && (
        <Material.Typography variant="body1">
          {newsletter.description}
        </Material.Typography>
      )}

      {newsletter.directLink && (
        <Material.Link
          style={linkStyle}
          className={classes.btn}
          href={newsletter.directLink as string}
        >
          {newsletter.label}
        </Material.Link>
      )}
    </Material.Grid>
  );
};

const NavigationColumn = ({
  section,
  linkStyle,
  _key: parentKey,
  children: socialIcons,
}: {
  section: any;
  linkStyle: any;
  _key: string;
  children: React.ReactNode;
}) => {
  if (!section) {
    return null;
  }

  return (
    <Material.Grid item xs={12} sm={6} md={3} className={classes.about}>
      <Material.Typography
        variant="h6"
        component="a"
        style={linkStyle}
        href={section.path || '#'}
        className={classes['sub-title']}
      >
        {section.label}
      </Material.Typography>

      {section.children.length > 0 && (
        <Material.List>
          {section.children.map((menuItem: IMenuItemNode, index: number) => (
            <Material.ListItem key={`${parentKey}_${index + 1}`}>
              <Material.Link
                style={linkStyle}
                href={menuItem.path || '#'}
                sx={{
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {menuItem.label}
              </Material.Link>
            </Material.ListItem>
          ))}
        </Material.List>
      )}
      {socialIcons}
    </Material.Grid>
  );
};

const SocialIcons = ({
  networks,
}: {
  networks: ISocialItem[];
  linkStyle: any;
}) => {
  const theme = useTheme();

  if (!networks || networks.length === 0) {
    return null;
  }

  return (
    <Material.List className={classes.social}>
      {networks
        .filter(({ name, url }) => name && url)
        .map((social, index) => (
          <Material.ListItem key={`footer_social_link_${index}`}>
            <SocialItem
              variant="filled"
              color={theme.palette.error.main}
              type={social.icon as SocialType}
              href={(social.url as string) || '#'}
            />
          </Material.ListItem>
        ))}
    </Material.List>
  );
};

export const StaticFooter: React.FunctionComponent = () => {
  return (
    <Material.Box
      sx={{ display: 'flex', justifyContent: 'center', marginTop: 0 }}
      className={classes['footer-container']}
    >
      <FooterLogoWrapper />
    </Material.Box>
  );
};

export default Footer;
