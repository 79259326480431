import { Typography } from '@mui/material';
import { useLayout } from '@orgnc/core/hooks';
import globalClasses from '../../global.module.scss';
import { StyledSecondaryLogoImage } from './secondary-logo-style';
import useCanonicalDomain from '../../../utils/useCanonicalDomain';
import { getDomainThemeId } from '../../../themes';

export const SecondaryLogo = ({ path }: { path?: string }) => {
  const { defaultImage } = useLayout();
  const domain = useCanonicalDomain();
  const themeId = getDomainThemeId(domain);
  const showDefaultImage = themeId === 'espnswfl' || themeId === undefined;

  return (
    <Typography
      variant="h6"
      noWrap
      component="a"
      href={path}
      sx={{
        margin: '10px auto 0',
        fontFamily: 'monospace',
        fontWeight: 700,
        letterSpacing: '.3rem',
        color: 'inherit',
        textDecoration: 'none',
        display:
          showDefaultImage && defaultImage
            ? { xs: 'flex', md: 'none' }
            : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: '15px',
      }}
    >
      {showDefaultImage && defaultImage && (
        <StyledSecondaryLogoImage
          className={globalClasses.imgFluid}
          title={defaultImage.title || ''}
          alt={defaultImage.altText || ''}
          src={defaultImage.sourceUrl as string}
          width={defaultImage.mediaDetails?.width || undefined}
          height={defaultImage.mediaDetails?.height || undefined}
        />
      )}
    </Typography>
  );
};
