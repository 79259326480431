export default function initializeVimeo(shouldKeepPriorVimeoPlayers: any) {
  if ((window as any).loadVimeoPlayers) {
    try {
      (window as any).loadVimeoPlayers(shouldKeepPriorVimeoPlayers);
    } catch (err: unknown) {
      // Handle errors and log the message if it's an Error object
      if (err instanceof Error) {
        // eslint-disable-next-line no-console
        console.log('Error while initializing Vimeo Prerolls', err.message);
      } else {
        // eslint-disable-next-line no-console
        console.log(
          'An unknown error occurred while initializing Vimeo Prerolls'
        );
      }
    }
  } else {
    // eslint-disable-next-line no-console
    console.log('Vimeo Players NOT configured for prerolls');
  }
}
