import { playStationFromUrl } from '@/redux/actions/player';
import { isWhiz } from '@/utils/whiz';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BmgGeneralOptions from './BmgGeneralOptions';
import ListenLive from './ListenLive';
import PlayStationFromUrl from './PlayStationFromUrl';

const GlobalScripts = () => {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const isMobileSSR = useSelector((state: any) => state.screen.isMobile);
  const { listenLiveVisibility } = BmgGeneralOptions();
  const isProduction = process.env.ENVIRONMENT === 'production';

  const addOrRemoveWhiz = (isMobileVar: boolean): void => {
    const { body } = document;
    const className = 'whiz';

    if (isMobileVar) {
      // Add the class only if it doesn't already exist
      if (!body.classList.contains(className)) {
        body.classList.add(className);
      }
    } else if (body.classList.contains(className)) {
      // Remove the class only if it exists
      body.classList.remove(className);
    }
  };

  useEffect(() => {
    // Check if it's a mobile device
    const isMobileDetect = isWhiz();

    setIsMobile(isMobileDetect);
    addOrRemoveWhiz(isMobileDetect);
  }, []);

  return (
    <>
      {!isMobile && !isMobileSSR && listenLiveVisibility && (
        <>
          {!isProduction && <ListenLive />}
          <PlayStationFromUrl
            dispatch={dispatch}
            playStationFromUrl={playStationFromUrl}
          />
          {/* <NewsletterScript /> */}
        </>
      )}
    </>
  );
};
export default GlobalScripts;
