import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { setStreams } from '@/redux/actions/player';
import useGlobalQuery from '@orgnc/core/hooks/useGlobalQuery';
import getStreams from '@/lib/queries/getStreams';
import bmgSettingsQuery from '@/lib/queries/bmgSettingsQuery';
import { setTunerPreroll } from '@/redux/actions/screen';

// Custom hook to fetch the publisher data
const useGeneralOptions = () => {
  const dispatch: Dispatch = useDispatch();
  const { data: bmgGeneralConfiguration } = useGlobalQuery(bmgSettingsQuery);
  const { data: streams } = useGlobalQuery(getStreams);

  const optPublisherId =
    bmgGeneralConfiguration?.acfOptionsGeneral?.generalOptions
      ?.bmgGeneralConfiguration?.optPublisherId ?? '';
  const listenLiveVisibility =
    bmgGeneralConfiguration?.acfOptionsGeneral?.generalOptions
      ?.bmgGeneralConfiguration?.listenLiveVisibility ?? false;
  const streamWpOption =
    bmgGeneralConfiguration?.acfOptionsGeneral?.generalOptions
      ?.bmgGeneralConfiguration?.streamWpOption ?? false;
  let gamTunerPreroll =
    bmgGeneralConfiguration?.acfOptionsGeneral?.generalOptions
      ?.bmgGeneralConfiguration?.gamTunerPreroll ?? '';
  const gamPublisherId =
    bmgGeneralConfiguration?.acfOptionsGeneral?.generalOptions
      ?.bmgGeneralConfiguration?.gamPublisherId ?? '';
  gamTunerPreroll = gamPublisherId
    ? `/${gamPublisherId}/${gamTunerPreroll}`
    : '';

  const streamsArr = streams?.bmgStreamsData ?? [];
  const bmgSettings = {
    optPublisherId,
    streamWpOption,
    listenLiveVisibility,
  };

  const fetchStreams = async (
    publisherId: string,
    fromBackend: boolean = false
  ) => {
    let data = [];
    if (!fromBackend) {
      const response = await fetch(
        `https://experience.bbgi.com/v1/experience/channels/${publisherId}/feeds/content/`
      );
      data = await response.json();
    } else {
      data = streamsArr;
    }

    const streamItems = data.filter((item: any) => item.type === 'stream');

    const transformedStreams: any = streamItems?.map((item: any) => {
      const streamContent = item.content[0];
      return {
        title: item.title || '',
        picture: {
          original: {
            url: item.picture.original.url,
          },
          large: {
            width: 1200,
            url: item.picture.large.url,
            height: 900,
          },
        },
        stream_call_letters: streamContent.stream_call_letters || '',
        stream_cmod_domain: streamContent.stream_cmod_domain || '',
        stream_tap_id: streamContent.stream_tap_id || '',
        phone: streamContent.phone || '',
        email: streamContent.email || '',
      };
    });
    if (typeof transformedStreams === 'object' && transformedStreams) {
      return transformedStreams;
    }
    return {};
  };

  useEffect(() => {
    const fetchGeneralOptions = async () => {
      try {
        if (optPublisherId) {
          const streamsData = await fetchStreams(
            optPublisherId,
            streamWpOption
          );
          if (streamsData) {
            dispatch(setStreams(streamsData));
          }
          dispatch(setTunerPreroll(gamTunerPreroll));
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching general options:', error);
      }
    };

    fetchGeneralOptions();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return bmgSettings;
};

export default useGeneralOptions;
