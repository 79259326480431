import { gql } from 'graphql-request';

/**
 * You can extend settings query using ExtendSettingsFragment,
 * graphql:codegen generates types using the fragment
 */
const extendSettingsFragment = gql`
  fragment ExtendSettingsFragment on RootQuery {
    stnPlayerSettings {
      featureVideoProvider
      stnBarkerId
      stnCategories
      stnCid
      stnInarticleId
      stnPosition
    }
    generalSettings {
      title
    }
  }
`;

export default extendSettingsFragment;
