import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '@/redux/actions/player';
import { AppDispatch } from '@/redux/store';
import FeatureLayoutControls from './Player/FeatureLayoutControls';

// TypeScript interfaces for props
interface AudioProps {
  src: string; // Audio source URL
  image: string; // Image URL (used for audio player)
  omny?: boolean; // Flag for Omny content
  title?: string; // Title of the audio content
  author?: string; // Author of the content
  sources?: any; // Dictionary of audio sources with MIME types
  tracktype: string; // Track type (for example: 'episode' or 'audio')
  episodeid: number; // Episode ID for tracking
  changeaudio: (episodeid: number) => void;
}

const Audio: React.FC<AudioProps> = ({
  src,
  image,
  omny = false,
  title = '',
  author = '',
  sources,
  tracktype,
  episodeid,
  changeaudio,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const audio = useSelector((state: any) => state.player.audio);
  const status = useSelector((state: any) => state.player.status);

  // Utility function to extract a title from the URL or sources
  const getTitle = useCallback((): string | false => {
    const extractTitle = (url: string | undefined): string => {
      // If url is undefined, return an empty string
      if (!url) return '';

      // Safely chain string operations using optional chaining and fallback values
      const fileName = url.split('/').pop() ?? ''; // Extract file name or empty string if undefined
      const withoutQueryParams = fileName.split('?')[0]; // Remove query parameters
      const withoutExtension = withoutQueryParams.split('.')[0]; // Remove file extension
      const titleNew = withoutExtension.split('_').join(' '); // Replace underscores with spaces

      return titleNew;
    };

    if (title) return title; // Use provided title if available
    if (src) return extractTitle(src); // Extract title from `src` if available
    if (sources) {
      const keys = Object.keys(sources);
      if (keys.length) return extractTitle(keys.shift()); // Extract title from sources if available
    }
    return false;
  }, [src, title, sources]);

  // Get the playable audio source URL based on MIME types supported by the browser
  const getPlayableSource = useCallback((): string => {
    if (typeof document === 'undefined') {
      return src;
    }
    const audioElement = document.createElement('audio');
    let maybe: string | false = false;
    const urls = Object.keys(sources || {});

    /* eslint-disable-next-line no-restricted-syntax */
    for (const url of urls) {
      const playable = audioElement.canPlayType(sources[url]);
      if (playable === 'probably') {
        return url;
      }
      if (playable === 'maybe' && !maybe) {
        maybe = url;
      }
    }

    return maybe || src;
  }, [src, sources]);

  // Handle play button click to either play Omny content or regular audio
  const handlePlayClick = useCallback(() => {
    const playableSrc = getPlayableSource();
    changeaudio(episodeid);

    if (omny) {
      dispatch(
        actions.playOmny(playableSrc, getTitle() as string, author!, tracktype)
      );
    } else {
      dispatch(
        actions.playAudio(
          playableSrc,
          getTitle() as string,
          author!,
          tracktype,
          image
        )
      );
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    dispatch,
    omny,
    getPlayableSource,
    getTitle,
    episodeid,
    author,
    tracktype,
    image,
  ]);

  // Determine the current status of the audio player
  const getStatus = useCallback((): string => {
    return audio === getPlayableSource() ? status : actions.STATUSES.LIVE_STOP;
  }, [audio, getPlayableSource, status]);

  return (
    <>
      <FeatureLayoutControls
        status={getStatus()}
        title={getTitle() as string}
        play={handlePlayClick}
        pause={() => dispatch(actions.pause())}
        resume={() => dispatch(actions.resume())}
      />
    </>
  );
};

export default Audio;
