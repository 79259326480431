import { Divider, ListItem, styled } from '@mui/material';

export const StyledExtraListItem = styled(ListItem)({
  padding: '0 !important',
});

export const StyledVerticalDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.text.primary,
  width: '1px',
  height: '50%',
  margin: '0',
}));
