import { Box, Typography } from '@mui/material';
import { RenderBlockProps } from '@orgnc/core/components/blocks/Blocks/helpers/RenderBlock';

const coreImageBlock: React.FunctionComponent<RenderBlockProps> = ({
  block,
}) => {
  const {
    alt,
    caption,
    creditText,
    title,
    url,
    width = 'auto',
    // align,
    // anchor,
    // aspectRatio,
    // className,
    // 'data-dimension': dataDimension,
    // height,
    // href,
    // id,
    // lightbox,
    // linkClass,
    // linkDestination,
    // linkTarget,
    // rel,
    // scale,
    // sizeSlug,
  } = block.attributes;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          maxWidth: width || 'auto',
        }}
      >
        <Box
          component="img"
          src={url}
          alt={alt}
          title={title}
          sx={{
            width: width || 'auto',
            position: 'relative',
          }}
        />
        {creditText && creditText !== '' ? (
          <Box
            component="span"
            sx={{
              position: 'absolute',
              bottom: '1rem',
              right: '1rem',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              color: 'white',
              paddingX: '0.5rem',
            }}
          >
            {creditText}
          </Box>
        ) : null}
      </Box>
      {caption && caption !== '' ? <Typography>{caption}</Typography> : null}
    </Box>
  );
};

export default coreImageBlock;
