import { DisablePrefetchLink } from '@orgnc/core/components/atoms';

export const OptionalLinkWrapper: React.FunctionComponent<{
  isLink: boolean;
  href: string;
  children: React.ReactNode;
}> = ({ isLink, href, children }) => {
  if (!isLink) {
    return <>{children}</>;
  }

  return (
    <DisablePrefetchLink href={href} passHref>
      {children}
    </DisablePrefetchLink>
  );
};
