import { Box, Typography } from '@mui/material';
import { IMediaFragment } from '@orgnc/core/generated/graphql-operations';
import { useLayout } from '@orgnc/core/hooks';
import { DisablePrefetchLink } from '@orgnc/core/components/atoms';
import globalClasses from '../../global.module.scss';
import { StyledLogoImage } from './logo-wrapper-style';
import useCanonicalDomain from '../../../utils/useCanonicalDomain';
import { getDomainThemeId } from '../../../themes';

export const LogoWrapper = ({
  path,
  isMobile = false,
}: {
  path: string;
  isMobile: boolean;
}) => {
  const {
    logos: { main: mainLogo },
    defaultImage,
  } = useLayout();
  const domain = useCanonicalDomain();
  const themeId = getDomainThemeId(domain);
  const showDefaultImage = themeId === 'espnswfl' || themeId === undefined;

  const renderImage = (source: IMediaFragment, className?: string) => {
    return (
      <StyledLogoImage
        className={[globalClasses.imgFluid, className].join(' ')}
        title={source.title ?? ''}
        alt={source.altText ?? ''}
        src={source.sourceUrl as string}
        width={source.mediaDetails?.width || undefined}
        height={source.mediaDetails?.height || undefined}
      />
    );
  };

  return (
    <DisablePrefetchLink passHref href={path}>
      <Typography
        variant="h6"
        noWrap
        component="a"
        sx={{
          fontFamily: 'monospace',
          fontWeight: 700,
          letterSpacing: '.3rem',
          color: 'inherit',
          textDecoration: 'none',
          display: {
            xs: isMobile ? 'flex' : 'none',
            xl: !isMobile ? 'flex' : 'none',
          },
          justifyContent: isMobile ? 'center' : 'start',
          alignItems: 'center',
          paddingRight: '15px',
        }}
      >
        <Box
          className="hello-world"
          sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}
        >
          {mainLogo && renderImage(mainLogo, 'main-logo')}
          {showDefaultImage &&
            defaultImage &&
            renderImage(defaultImage, 'default-image')}
        </Box>
      </Typography>
    </DisablePrefetchLink>
  );
};
