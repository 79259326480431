import { gql } from 'graphql-request';
import { GlobalQuery } from '@orgnc/core/lib/themes/queries';
import { IRootQuery } from '@orgnc/core/generated/graphql-operations';

export const query = gql`
  query HtlSettingsQuery {
    acfOptionsGeneral {
      generalOptions {
        htlAds {
          isProduction
          ctest
          genre
          market
          siteId
        }
      }
    }
  }
`;

const htlSettingsQuery: GlobalQuery<IRootQuery> = {
  name: 'htlSettingsQuery',
  query,
};

export default htlSettingsQuery;
