import React, { useState, ComponentType, useCallback, useEffect } from 'react';
import next from '@/../public/images/next-icon.png';
import prev from '@/../public/images/prev-icon.png';
import { FeatureLayoutPodcastScrubber, FeatureLayoutVolume } from './Player';
import Audio from './Audio';

const PodcastSection: ComponentType<any> = ({ area, block, frequency }) => {
  const [podcastToShowTitle, setPodcastToShowTitle] = useState<string>(
    block?.attributes?.selectName || ''
  );

  const decodeHtmlEntities = useCallback((html: string): string => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.documentElement.textContent || '';
  }, []);

  useEffect(() => {
    setPodcastToShowTitle(decodeHtmlEntities(podcastToShowTitle) || 'Podcast');
  }, [podcastToShowTitle, decodeHtmlEntities]);

  const formattedEpisodes: any = block?.podcastEpisodes?.edges?.map(
    ({ node }: any) => ({
      episode_id: node.databaseId,
      episodes_title: node.title,
      episodes_url: node.link,
      episodes_image: node.episodeImageUrl,
      episodes_mp3_url: node.episodeAudioUrl,
      episodes_author: node.author?.node?.name,
      track_type: 'live', // Static value as per your requirement
    })
  );

  const leftEpisodeInit = formattedEpisodes ? formattedEpisodes[0] : null;
  const currentEpisodeIdInit = formattedEpisodes
    ? formattedEpisodes[0].episode_id
    : null;
  const rightEpisodeInit = formattedEpisodes || [];
  const allEpisodes = formattedEpisodes || [];

  const [currentEpisodeId, setCurrentEpisodeId] = useState<number | null>(
    currentEpisodeIdInit
  );
  const [leftEpisode, setLeftEpisode] = useState<any>(leftEpisodeInit);
  const [rightEpisode, setRightEpisode] = useState<any>(rightEpisodeInit);

  const findIndexByEpisodeId = (episodeId: any): number => {
    return allEpisodes.findIndex(
      (episode: any) => episode.episode_id === episodeId
    );
  };

  const handleNext = () => {
    if (currentEpisodeId !== null) {
      const nextEpisodeIndex = findIndexByEpisodeId(currentEpisodeId);
      if (allEpisodes[nextEpisodeIndex + 1]) {
        const nextEpisode = allEpisodes[nextEpisodeIndex + 1];
        setLeftEpisode(nextEpisode);
        setCurrentEpisodeId(nextEpisode.episode_id);
      }
    }
  };

  const handlePrev = () => {
    if (currentEpisodeId !== null) {
      const prevEpisodeIndex = findIndexByEpisodeId(currentEpisodeId);
      if (allEpisodes[prevEpisodeIndex - 1]) {
        const prevEpisode = allEpisodes[prevEpisodeIndex - 1];
        setLeftEpisode(prevEpisode);
        setCurrentEpisodeId(prevEpisode.episode_id);
      }
    }
  };

  const handleChangeAudio = (episodeId: number) => {
    setCurrentEpisodeId(episodeId);
    const leftEpisodeData = allEpisodes.find(
      (ep: any) => ep.episode_id === episodeId
    );
    const rightEpisodesData = allEpisodes.filter(
      (ep: any) => ep.episode_id !== episodeId
    );
    setLeftEpisode(leftEpisodeData || null);
    setRightEpisode(rightEpisodesData);
  };

  return (
    <div data-og-block-area={area} data-og-block-nth={frequency}>
      <section className="container_wrap podcast_div_wrap">
        <div className="main_title dark_text mt_80">
          <h2>{podcastToShowTitle}</h2>
        </div>
        <div className="podcast_div">
          <div className="podcast_left_box">
            {leftEpisode ? (
              <>
                <figure className="img_box">
                  {/* eslint-disable-next-line @next/next/no-img-element */}
                  <img
                    src={leftEpisode.episodes_image}
                    alt={leftEpisode.episodes_title}
                  />
                </figure>
                <div className="content_wrap">
                  <h3>{leftEpisode.episodes_title}</h3>
                  <span>&nbsp; {/* by {leftEpisode.episodes_author} */}</span>
                  <div className="music_frame">
                    <div className="d-flex align-items-center mb-3 w-100 music_frame_wrap">
                      <div className="d-flex align-items-center mr_30  play_icon">
                        <button
                          type="button"
                          className="music_icon"
                          onClick={handlePrev}
                          style={{
                            background: 'none',
                            border: 'none',
                            padding: 0,
                            textDecoration: 'underline',
                            color: 'blue',
                            cursor: 'pointer',
                          }}
                        >
                          {/* eslint-disable-next-line @next/next/no-img-element */}
                          <img src={prev.src} alt="" />
                        </button>
                        <div className="music_icon">
                          <Audio
                            src={leftEpisode.episodes_mp3_url}
                            title={leftEpisode.episodes_title}
                            author={leftEpisode.episodes_author}
                            tracktype={leftEpisode.track_type}
                            changeaudio={handleChangeAudio}
                            image={leftEpisode.episodes_image}
                            episodeid={leftEpisode.episode_id}
                          />
                        </div>
                        <button
                          type="button"
                          onClick={handleNext}
                          style={{
                            background: 'none',
                            border: 'none',
                            padding: 0,
                            textDecoration: 'underline',
                            color: 'blue',
                            cursor: 'pointer',
                          }}
                        >
                          {/* eslint-disable-next-line @next/next/no-img-element */}
                          <img src={next.src} alt="" />
                        </button>
                      </div>
                      <FeatureLayoutVolume />
                    </div>
                    <div
                      className="progress_frame"
                      style={{
                        display: 'none',
                      }}
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="covered_duration">3:46</p>
                        <p className="total_duration">12:00</p>
                      </div>
                    </div>
                    <FeatureLayoutPodcastScrubber />
                  </div>
                </div>
              </>
            ) : (
              <p>No podcast data available.</p>
            )}
          </div>
          <div className="podcast_right_box">
            <div className="scroll_div">
              {rightEpisode.length > 0 ? (
                rightEpisode.map(
                  (item: any) =>
                    currentEpisodeId !== item.episode_id && (
                      <article key={item.episode_id} className="podcast_box">
                        <figure className="pod_img">
                          <Audio
                            src={item.episodes_mp3_url}
                            title={item.episodes_title}
                            author={item.episodes_author}
                            tracktype={item.tracktype}
                            changeaudio={handleChangeAudio}
                            image={item.episodes_image}
                            episodeid={item.episode_id}
                          />
                          {/* eslint-disable-next-line @next/next/no-img-element */}
                          <img
                            src={item.episodes_image}
                            alt={item.episodes_title}
                          />
                        </figure>
                        <div className="pod_content">
                          <a href={item.episodes_url}>{item.episodes_title}</a>
                          <span>&nbsp; {/* by {item.episodes_author}  */}</span>
                        </div>
                      </article>
                    )
                )
              ) : (
                <p>No additional podcasts available.</p>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PodcastSection;
