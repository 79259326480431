import React, { ChangeEvent, createRef, PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { seekPosition } from '@/redux/actions/player';
import { RootState } from '@/redux/store';

// Define the component's props
interface StateProps {
  time: number;
  duration: number;
}

interface DispatchProps {
  seek: (time: number) => void;
}

type Props = StateProps & DispatchProps;

class FeatureLayoutPodcastScrubber extends PureComponent<Props> {
  private sliderRef = createRef<HTMLInputElement>();

  private onSeek = this.handleSeekPosition.bind(this);

  static format(time: number): string {
    const HOUR_IN_SECONDS = 3600;
    const MINUTE_IN_SECONDS = 60;

    const hours = Math.floor(time / HOUR_IN_SECONDS);
    const minutes = Math.floor((time % HOUR_IN_SECONDS) / MINUTE_IN_SECONDS);
    const seconds = Math.floor(time % MINUTE_IN_SECONDS);

    const toFixed = (value: number): string =>
      value.toString().length === 2 ? value.toString() : `0${value}`;
    let result = `${toFixed(minutes)}:${toFixed(seconds)}`;
    if (hours > 0) {
      result = `${toFixed(hours)}:${result}`;
    }
    return result;
  }

  private handleSeekPosition(e: ChangeEvent<HTMLInputElement>): void {
    const time = parseFloat(e.target.value) || 0;
    this.props.seek(time);
  }

  render() {
    const { time, duration } = this.props;

    // Thumb is 12px so adjust prebar width
    const progressPercentage = time / duration;
    const sliderWidth = this.sliderRef.current
      ? this.sliderRef.current.clientWidth
      : 0;
    let prebarWidth =
      progressPercentage * sliderWidth - progressPercentage * 12;

    if (prebarWidth < 0) {
      prebarWidth = 0;
    }

    return (
      <div className="controls-progress">
        <div className="ee-range-input -progress">
          <input
            ref={this.sliderRef}
            type="range"
            min="0"
            max={duration}
            value={time}
            onChange={this.onSeek}
          />
          <p className="pre-bar" style={{ width: `${prebarWidth}px` }} />
        </div>
        <span
          className={
            time && time > 3599
              ? 'time -desktop6digits'
              : 'time -desktop4digits'
          }
        >
          {FeatureLayoutPodcastScrubber.format(time)}
        </span>
        <span
          className={
            duration && duration > 3599
              ? 'time -desktop6digits'
              : 'time -desktop4digits'
          }
        >
          {FeatureLayoutPodcastScrubber.format(duration)}
        </span>
      </div>
    );
  }
}

const mapStateToProps = ({ player }: RootState) => ({
  time: player.time,
  duration: player.duration,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ seek: seekPosition }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeatureLayoutPodcastScrubber);
