import { RenderBlockProps } from '@orgnc/core/components/blocks/Blocks/helpers/RenderBlock';
import Script from 'next/script';
import { type ComponentType, type PropsWithChildren } from 'react';

const AudienceEmbedBlock: ComponentType<PropsWithChildren<RenderBlockProps>> = (
  props
) => {
  const { block } = props;
  let { widgetUrl, widgetId, widgetType } = block.attributes;

  function decodeUrl(url: string) {
    // Check if the URL is already decoded
    if (url.indexOf('%') === -1) {
      return url;
    }
    // Decode the URL-encoded string
    return decodeURIComponent(url);
  }

  console.log({ attr: block.attributes });

  widgetUrl = encodeURIComponent(widgetUrl);
  widgetId = String(widgetId).trim();
  widgetType = String(widgetType).trim();

  // Return empty string if both widgetUrl and widgetId are empty
  if (!widgetUrl && !widgetId) {
    return null;
  }

  // If widgetUrl is provided but widgetId is not, extract widgetId and widgetType from URL
  if (widgetUrl && !widgetId) {
    const urlRegex = /.*#([a-z]+)-*[a-z]*\/([0-9]+).*/;
    const matches = decodeUrl(widgetUrl).match(urlRegex) ?? [];
    console.log({ matches, widgetUrl });
    const [, firstMatch, secondMatch] = matches;

    if (matches && secondMatch) {
      widgetId = secondMatch;
      widgetType = firstMatch ?? '';
    } else {
      return null;
    }
  }

  return (
    <>
      <Script src="https://campaign.aptivada.com/sdk.js" />
      <Script id="audience-embed-script" strategy="afterInteractive">
        {`window.AptivadaAsyncInit = function(){
          window.Aptivada.init({
              campaignId: ${widgetId},
              campaignType: "${widgetType}",
              events: {
                  userCompleteEntry: function(e){
                      console.log('user complete entry', e);
                  },
              }
          })
        }`}
      </Script>
      <div className="aptivada-campaign"></div>
    </>
  );
};

export default AudienceEmbedBlock;
