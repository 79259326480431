import React from 'react';
import { Box, SxProps } from '@mui/material';
import useGlobalQuery from '@orgnc/core/hooks/useGlobalQuery';
import htlSettingsQuery from '@/lib/queries/htlSettingsQuery';

export type HashtagLabsAdProps = {
  sx?: SxProps;
  hasBaseHeight?: boolean;
  unit: string;
};

const HashtagLabsAd: React.FunctionComponent<HashtagLabsAdProps> = (props) => {
  const { sx, unit } = props;

  const { data: htlSettings } = useGlobalQuery(htlSettingsQuery);
  const site =
    htlSettings?.acfOptionsGeneral?.generalOptions?.htlAds?.siteId ?? 'WBCN';

  return (
    <Box
      sx={{
        minHeight: props.hasBaseHeight ? '250px' : undefined,
        ...(sx ?? {}),
      }}
    >
      <div className={`opscoad-${unit}`} data-unit={`${site}/${unit}`}></div>
    </Box>
  );
};

export default HashtagLabsAd;
