import React, { useEffect, useState } from 'react';

import { RenderBlockProps } from '@orgnc/core/components/blocks/Blocks/helpers/RenderBlock';
import { BlockFragment } from '@orgnc/core/lib/wordpress/api/fragments/blocksFragment';

interface VoteEIBlockProps {
  data: {
    id?: string;
  };
}

const VoteEIBlock: React.FunctionComponent<RenderBlockProps> = ({
  block: props,
}) => {
  const block = props as unknown as BlockFragment<VoteEIBlockProps>;
  const [frameHeight, setFrameHeight] = useState(550);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      // Only accept messages from voteei.com
      if (event.origin !== 'https://voteei.com') return;

      // Expecting a message with height information
      if (
        typeof event.data === 'object' &&
        event.data.type === 'resize' &&
        typeof event.data.height === 'number'
      ) {
        setFrameHeight(event.data.height);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const { id = '' } = block.attributes.data;

  return (
    <div className="vote-ei-container">
      <iframe
        src={`https://voteei.com/${id}`}
        title="Vote EI"
        width="100%"
        height={`${frameHeight}px`}
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        style={{ border: 'none' }}
      />
    </div>
  );
};

export default VoteEIBlock;
