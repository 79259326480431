import { useRouter } from 'next/router';
import React, { useContext } from 'react';

export const cleanupDomain = (domain: string) => {
  return domain
    .replace('.localhost:3000', `.${process.env.NEXT_PUBLIC_ROOT_DOMAIN}`)
    .replace('lcl.', '')
    .replace('stg.', '')
    .replace('prodnew.', '')
    .replace('www.', '');
};

export const parseCanonicalDomain = (
  pathname: string,
  hostname?: string | null
) => {
  if (!pathname.includes('/domains/')) {
    return hostname ? cleanupDomain(hostname) : null;
  }

  const segments = pathname.split('/domains/');

  const potentialDomain = segments?.[1]?.split('/')?.[0] ?? null;
  if (potentialDomain === null) {
    return null;
  }
  return new URL(`http://${potentialDomain}`).hostname;
};

export const HostnameContext = React.createContext<string | null>(null);

export const HostnameProvider: React.FunctionComponent<{
  hostname: string | null;
  children: React.ReactNode;
}> = ({ children, hostname }) => {
  const clientHostname =
    typeof window !== 'undefined' && window.location.hostname
      ? window.location.hostname
      : null;

  return (
    <HostnameContext.Provider value={hostname ?? clientHostname}>
      {children}
    </HostnameContext.Provider>
  );
};

const useCanonicalDomain = () => {
  const router = useRouter();
  const hostname = useContext(HostnameContext);
  return parseCanonicalDomain(router.pathname, hostname);
};

export default useCanonicalDomain;
