import HashtagLabs from '@/components/HashtagLabs';
import HashtagLabsAd from '@/components/HashtagLabsAd';
import NewsletterPlaceholder from '@/components/NewsletterPlaceholder';
import ElementsPageTemplate from '@orgnc/core/components/themes/Elements/components/templates/Page';
import { IPageTemplateProps } from '@orgnc/core/lib/themes/templates/page';

const PageTemplate = ({ page, options }: IPageTemplateProps) => {
  const afterContentSlot = (
    <>
      <NewsletterPlaceholder />
      <HashtagLabsAd unit={'WideBanner_Scroll'} />
    </>
  );

  return (
    <>
      <HashtagLabs />
      <HashtagLabsAd
        unit={'WideBanner_Top'}
        sx={{ mt: { xs: 8, sm: 2 }, height: 250 }}
      />
      <ElementsPageTemplate
        page={page}
        options={options}
        afterContentSlot={afterContentSlot}
      />
    </>
  );
};

export default PageTemplate;
