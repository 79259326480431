/**
 * @file This is the Stations React component.
 * It handles the rendering of other available stations for the user.
 * Additionally, it filters and handles the click functionality to play the selected station.
 */

import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { playStation } from '@/redux/actions/player';
import { RootState } from '@/redux/store';
import { loadTdSk } from '../../utils/tdSdk';

// Extend Day.js plugins
dayjs.extend(utc);
dayjs.extend(timezone);

interface StateProps {
  stream: any;
  streams: any;
}

interface DispatchProps {
  play: (station: string) => void;
}

type Props = StateProps & DispatchProps;

class Stations extends Component<Props> {
  private stationModalRef = createRef<HTMLDivElement>();

  /**
   * Handles the play button click, dispatches the play action with given station.
   * @param {Object} station The station to play.
   */
  handlePlayClick(station: any) {
    if (station) {
      loadTdSk()
        .then(() => {
          this.props.play(station);
        })
        .catch(() => {});
    }
  }

  /**
   * Determines whether the stations should be rendered given their stream and streams.
   * @param {Object} stream The current stream.
   * @param {Array} streams The list of available streams.
   * @return {boolean} True if stations should be rendered, false otherwise.
   */
  // eslint-disable-next-line class-methods-use-this
  shouldRender(stream: any, streams: any) {
    if (!stream) {
      return true;
    }

    if (streams && streams.length > 1) {
      return true;
    }

    return false;
  }

  /**
   * Checks and returns the timezone from the config.
   * @return {string|boolean} The timezone if it exists, false otherwise.
   */
  // eslint-disable-next-line class-methods-use-this
  checkTimeZone() {
    // const tz = config.timezone_string;
    const tz = 'America/New_York';
    if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) {
      return tz;
    }

    try {
      Intl.DateTimeFormat(undefined, { timeZone: tz });
      return tz;
    } catch (ex) {
      return false;
    }
  }

  /**
   * Renders the list of stations, filtering by the time if their availability is conditional.
   * @param {Object} stream The current stream.
   * @param {Array} streams The list of available streams.
   * @return {Array} An array of rendered station buttons.
   */
  renderStations(stream: any, streams: any) {
    const stations = [];
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const timezoneString = this.checkTimeZone();
    if (timezoneString) {
      dayjs.tz.setDefault(timezoneString);
    }

    const dayName = days[dayjs.tz().day()].toLowerCase();
    const dayTime = dayjs.tz();

    // this code block is to handle the case where there are multiple streams
    /* eslint-disable camelcase, @typescript-eslint/naming-convention, no-continue */
    const secondaryStreams = streams.filter((s: any) => s !== stream);
    for (let i = 0; i < secondaryStreams.length; i += 1) {
      const {
        title, // title of the stream
        stream_call_letters, // call letters of the stream
        picture, // logo of the stream
        secondStreamTime, // availability of the stream
      } = secondaryStreams[i]; // stream object
      const { large, original } = picture || {};
      const { url } = large || original || {};

      let logo: any = false;
      if (url) {
        // eslint-disable-next-line @next/next/no-img-element
        logo = <img src={url} alt={title} />;
      }

      // if the stream is conditional, check if it is available
      if (secondStreamTime) {
        let { startTime, endTime }: any = '';

        // if the stream is not available on the current day, skip it
        if (!(dayName in secondStreamTime)) {
          continue;
        }

        if (secondStreamTime[dayName].startTime) {
          const temp = secondStreamTime[dayName].startTime.split(':');
          startTime = dayjs.tz().set('hour', temp[0]).set('minute', temp[1]);
        }

        if (secondStreamTime[dayName].endTime) {
          const temp = secondStreamTime[dayName].endTime.split(':');
          endTime = dayjs.tz().set('hour', temp[0]).set('minute', temp[1]);
        }
        // if the stream is not available at the current time, skip it
        if (dayTime < startTime || dayTime > endTime) {
          continue;
        }
      }

      stations.push(
        <div key={stream_call_letters}>
          <button
            type="button"
            className="control-station-button"
            onClick={this.handlePlayClick.bind(this, stream_call_letters)}
          >
            {logo}
            <span>{title}</span>
          </button>
        </div>
      );
    }
    /* eslint-enable camelcase, @typescript-eslint/naming-convention, no-continue */

    return stations;
  }

  render() {
    const { stream, streams }: any = this.props;

    if (!this.shouldRender(stream, streams)) {
      return false;
    }

    return this.renderStations(stream, streams);
  }
}

function mapStateToProps({ player }: RootState) {
  const { streams, station } = player;

  return {
    stream: streams.find((item: any) => item.stream_call_letters === station),
    streams,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ play: playStation }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Stations);
