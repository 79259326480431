import { Button, styled } from '@mui/material';

export const StyledMyListenerControllerButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== 'mainColour' && prop !== 'secondaryColour',
})<any>(({ mainColour, secondaryColour }) => ({
  display: 'flex',
  alignItems: 'center',
  borderTopLeftRadius: '22.5px !important',
  borderBottomLeftRadius: '22.5px !important',
  backgroundColor: `${mainColour} !important`,
  height: '45px',
  fontSize: '14px',
  fontWeight: '700 !important',
  padding: '0 10px',
  borderRadius: 0,
  justifyContent: 'center',
  color: `${secondaryColour} !important`,

  '& span': {
    marginLeft: 0,
    fontSize: 'inherit',
    fontWeight: 'inherit',
    '& svg': {
      strokeWidth: 2,
    },
  },

  '& .status': {
    width: '28px',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    color: '#e63838',

    '& .btn-default': {
      background: 'none',
    },

    '& button': {
      width: '29px',
      height: '29px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: `${secondaryColour} !important`,
      borderRadius: '100px',
      cursor: 'pointer',
      border: '0px',

      '& .loading': {
        '&::after': {
          border: `0.1em solid ${mainColour}`,
          borderRightColor: 'transparent',
          borderTopColor: 'transparent',
        },
      },

      '& div': {
        display: 'flex',
      },

      '& svg': {
        width: '13px',
        height: '12px',
        fill: mainColour,

        '& rect, & path': {
          fill: mainColour,
        },
      },
    },
  },

  '@media (min-width: 768px)': {
    height: '50px',
    fontSize: '18px',
    padding: '0 10px',

    '& > span > svg.play-icon': {
      fontSize: '35px',
    },
  },
}));
