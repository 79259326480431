import { Box, Container } from '@mui/material';
import { useLayout } from '@orgnc/core/hooks';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isWhiz } from '@/utils/whiz';
import { DesktopMenu } from './desktop-menu/desktop-menu';
import { ExtraItems } from './extra-items/extra-items';
import { StyledHeaderContainer, StyledMainNavigation } from './header-style';
import { LogoWrapper } from './logo-wrapper/logo-wrapper';
import { MobileMenu } from './mobile-menu/mobile-menu';
import { SecondaryLogo } from './secondary-logo/secondary-logo';

const Header = () => {
  const {
    menus: { HEADER_NAVIGATION },
    networks,
  } = useLayout();
  const [isMobile, setIsMobile] = useState(false);
  const isMobileSSR = useSelector((state: any) => state.screen.isMobile);

  useEffect(() => {
    setIsMobile(isWhiz());
  }, []);

  if (isMobile || isMobileSSR) {
    // Return an empty header if it's a mobile view
    return null;
  }

  const getFilteredNavigationMenu = (isHome = false) => {
    return HEADER_NAVIGATION.filter((menu) => {
      if (isHome) {
        return menu.label?.toLowerCase() === 'home';
      }

      return menu.label?.toLowerCase() !== 'home';
    });
  };

  const siteUrl = getFilteredNavigationMenu(true)[0]?.path || '/';

  return (
    <StyledHeaderContainer
      position="sticky"
      // className={classes['header-container']}
      // sx={{ backgroundColor: 'secondary' }}
    >
      <Container maxWidth={false} sx={{ padding: { xs: '3px 0' } }}>
        <StyledMainNavigation>
          {HEADER_NAVIGATION.length > 0 && (
            <React.Fragment>
              <LogoWrapper path={siteUrl} isMobile={false} />

              <Box
                sx={{
                  display: { xs: 'flex', xl: 'none' },
                  justifyContent: 'start',
                }}
              >
                <MobileMenu
                  aNavigations={getFilteredNavigationMenu()}
                  networks={networks}
                />
              </Box>

              <DesktopMenu aNavigations={getFilteredNavigationMenu()} />
              <LogoWrapper path={siteUrl} isMobile={true} />
            </React.Fragment>
          )}
          <ExtraItems networks={networks} />
        </StyledMainNavigation>
        <SecondaryLogo path={siteUrl} />
      </Container>
    </StyledHeaderContainer>
  );
};

export default Header;
