import { AppBar, styled } from '@mui/material';

export const StyledHeaderContainer = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.brand.header,
  color: theme.palette.brand.text,
  padding: '5px 15px',
  fontFamily: 'inherit',
  [theme.breakpoints.up('xl')]: {
    paddingRight: '15px',
  },
  '& ul, li': {
    padding: '0 !important',
  },
  '& li:not(:last-child)': {
    paddingRight: '15px !important',
  },
  '& a': {
    color: theme.palette.brand.text,
  },
}));

export const StyledMainNavigation = styled('div')(({ theme }) => ({
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: '1fr auto 1fr',
  [theme.breakpoints.up('xl')]: {
    gridTemplateColumns: 'auto 1fr auto',
  },
}));
