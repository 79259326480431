import HashtagLabsAd from '@/components/HashtagLabsAd';
import { RenderBlockProps } from '@orgnc/core/components/blocks/Blocks/helpers/RenderBlock';
import { BlockFragment } from '@orgnc/core/lib/wordpress/api/fragments/blocksFragment';

export type HtlAdProps = {
  unit: string;
  hasBaseHeight?: boolean;
};

const HtlAd: React.FunctionComponent<RenderBlockProps> = ({ block }) => {
  const props = block as unknown as BlockFragment<HtlAdProps>;

  return HashtagLabsAd({
    unit: props.unit,
    hasBaseHeight: props?.hasBaseHeight ?? true,
  });
};

export default HtlAd;
