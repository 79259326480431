// Import saga effects
import { put, takeLatest, select, call } from 'redux-saga/effects';

// Import action constant(s)
// import { showListenLive } from '../../actions/screen';
import { PlayerState } from '@/redux/reducers/player';
import { getPlayerFromGlobal } from '@/redux/utilities';
import { ACTION_AD_PLAYBACK_STOP } from '../../actions/player';

function* hidePrerollShade() {
  const prerollWrapper = document.querySelector('div.preroll-wrapper.-active');
  if (prerollWrapper) {
    yield call([prerollWrapper.classList, 'remove'], '-active');
  }

  const gamPrerollWrapper = document.querySelector(
    'div.gampreroll-wrapper.-active'
  );
  if (gamPrerollWrapper) {
    yield call([gamPrerollWrapper.classList, 'remove'], '-active');
  }
}

/*
function* brieflyShowPlayerDropdown() {
	const listenlivecontainer = document.getElementById('my-listen-dropdown2');
	const listenliveStyle = window.getComputedStyle(listenlivecontainer);
	if (listenliveStyle.display !== 'block') {
		yield put(refreshDropdownAd());
		yield call(hidePrerollShade);
		listenlivecontainer.style.display = 'block';
		const delay = ms => new Promise(res => setTimeout(res, ms));
		yield delay(3500);
		yield put(hideDropdownAd());
		listenlivecontainer.style.display = 'none';
	}
}
*/

/**
 * @function yieldAdPlaybackStop
 * Runs whenever ACTION_AD_PLAYBACK_STOP is dispatched
 *
 * @param {Object} action dispatched action
 * @param {Object} action.payload payload from dispatch
 */
function* yieldAdPlaybackStop({ payload }: any) {
  // Destructure from payload
  const { actionType } = payload;

  // Player store from state
  const playerStore: PlayerState = yield select(({ player }) => player);

  // Destructure from playerStore
  const { adPlayback, station, playerType } = playerStore;
  let { player } = playerStore;

  player = getPlayerFromGlobal(player, playerType);

  // Update DOM
  yield call([document.body.classList, 'remove'], 'locked');

  // If the current player is a tdplayer
  if (playerType === 'tdplayer') {
    // If adPlayback and player.skipAd
    if (adPlayback && typeof player.skipAd === 'function') {
      yield call([player, 'skipAd']);
    }

    // If station and player.skipAd
    if (station && typeof player.play === 'function') {
      yield call([player, 'play'], {
        station,
        trackingParameters: { dist: 'beasleyweb' },
      });
    }
  }

  yield call(hidePrerollShade);
  // yield put(showListenLive({ isTriggeredByStream: true }));

  // finalize dispatch
  yield put({ type: actionType });
}

/**
 * @function watchAdPlaybackStop
 * Watches for playback stop.
 */
export default function* watchAdPlaybackStop() {
  yield takeLatest([ACTION_AD_PLAYBACK_STOP], yieldAdPlaybackStop);
}
