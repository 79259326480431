import React, { Component, ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryProps {
  children: ReactNode; // Type for children passed to the component
}

interface ErrorBoundaryState {
  hasError: boolean; // State to track if an error occurred
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false }; // Initial state
  }

  // Update state if an error is thrown
  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  // Log the error details
  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error: Error, info: ErrorInfo): void {
    // eslint-disable-next-line no-console
    console.error('Error caught in ErrorBoundary:', error, info); // Log error and stack trace
  }

  render() {
    // Render children if no error, otherwise fallback
    return this.state.hasError ? (
      <h1>Something went wrong. Please try again later.</h1> // Replace with a custom UI if needed
    ) : (
      this.props.children
    );
  }
}

export default ErrorBoundary;
