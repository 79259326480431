import { InputBase, ListItem, Paper, styled } from '@mui/material';

export const StyledMobileSearchFieldPaper = styled(Paper)<any>(({ theme }) => ({
  'form&': {
    padding: '15px 14px',
  },
  backgroundColor: 'transparent',
  boxShadow: 'none',

  '& > .search-icon, & > .search-input': {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    color: theme.palette.brand.text,
    margin: '0',
    height: '40px',
  },

  '& > .search-icon': {
    borderRadius: 'unset',
  },

  '& > .search-input > input': {
    fontSize: '0.7778rem',
    backgroundColor: 'transparent',
    // border: 'none',
    fontWeight: '500',
    padding: '0.6667rem 1rem 0.6667rem 0.5rem',
    width: '100%',
    lineHeight: '1.4',
    fontFamily: 'inherit',
    color: theme.palette.brand.text,
    '&::placeholder': {
      color: theme.palette.brand.text,
      // color: 'blue',
    },
  },
}));

export const StyledDesktopSearchForm = styled(ListItem)<any>(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    position: 'relative',
  },
}));

export const StyledDesktopSearchField = styled(InputBase)(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    position: 'absolute',
    right: '0',
    top: '100%',

    '& input': {
      padding: '10px 15px',
      width: '285px',
      transition: 'width 0.55s ease',
      color: '#000',
      background: '#ddd',
      borderRadius: '33px',
      fontSize: '16px',
      height: '33px',
    },
  },
}));
