/**
 * @file Offline Next.js TypeScript component
 * Displays a message when there is no internet connection detected.
 */

import React, { FC } from 'react';

const Offline: FC = () => (
  <div className="offline">
    <span className="offline-title">No internet connection detected</span>
    <span className="offline-description">
      Audio will automatically try to reconnect when it detects an internet
      connection.
    </span>
  </div>
);

export default Offline;
