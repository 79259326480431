import { gql } from 'graphql-request';
import { GlobalQuery } from '@orgnc/core/lib/themes/queries';
import { IRootQuery } from '@orgnc/core/generated/graphql-operations';

export const query = gql`
  query GetStreamsQuery {
    bmgStreamsData {
      title
      type
      content {
        phone
        email
        stream_call_letters
        stream_cmod_domain
        stream_tap_id
      }
      picture {
        original {
          url
        }
        large {
          url
        }
      }
    }
  }
`;

const getStreams: GlobalQuery<IRootQuery> = {
  name: 'getStreams',
  query,
};

export default getStreams;
