import React from 'react';

interface FeatureLayoutControlsProps {
  status: string;
  title?: string;
  play: () => void;
  pause: () => void;
  resume: () => void;
  colors?: React.CSSProperties;
  isIos?: boolean;
}

const FeatureLayoutControls: React.FC<FeatureLayoutControlsProps> = ({
  status,
  title = '',
  play,
  pause,
  resume,
  colors,
  isIos,
}) => {
  // TODO - IOS Special Style was removed from controls.css. Remove osClass once it is determined that we will never need OS Specific logic again.
  const osClass = isIos ? '-is-ios' : '';

  return (
    <div className={`status ${status} ${osClass}`}>
      <button
        type="button"
        className="play-btn"
        onClick={play}
        aria-label={`Play ${title}`}
        style={colors}
      >
        <div>
          <svg viewBox="-2 0 17 25" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.1836 12.0055L0.910156 23.124L0.910156 0.887031L16.1836 12.0055Z" />
          </svg>
        </div>
      </button>

      <button
        type="button"
        className="pause-btn"
        onClick={pause}
        aria-label="Pause"
        style={colors}
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="23"
            fill="none"
          >
            <rect width="4" height="23" rx="1" fill="#000" />
            <rect x="9" width="4" height="23" rx="1" fill="#000" />
          </svg>
        </div>
      </button>

      <button
        type="button"
        className="resume-btn"
        onClick={resume}
        aria-label="Resume"
        style={colors}
      >
        <div>
          <svg viewBox="-2 0 17 25" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.1836 12.0055L0.910156 23.124L0.910156 0.887031L16.1836 12.0055Z" />
          </svg>
        </div>
      </button>

      <button
        type="button"
        className="loading-btn"
        aria-label="Loading"
        style={colors}
      >
        <div className="loading" />
      </button>
    </div>
  );
};

export default FeatureLayoutControls;
