import { Button, Menu, styled } from '@mui/material';

export const StyledDesktopMenuBox = styled('div')(({ theme }) => ({
  display: 'none',
  paddingRight: '15px',
  [theme.breakpoints.up('xl')]: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const StyledDesktopMenuLink = styled(Button)(({ theme }) => ({
  color: theme.palette.brand.text,
  padding: '0 15px',
  lineHeight: '40px',
  height: '40px',
  fontSize: '14px',
  fontFamily: 'inherit',
  textTransform: 'capitalize',
  '& > span svg': {
    transition: 'transform 0.15s ease',
    stroke: theme.palette.primary.main,
    fill: theme.palette.primary.main,
  },

  '&.active': {
    color: theme.palette.primary.main,
    borderBottomLeftRadius: 'unset',
    borderBottomRightRadius: 'unset',

    '& > span svg': {
      transform: 'rotate(180deg)',
    },
  },

  '&:hover, &.active': {
    backgroundColor: theme.palette.brand.headerHighlight,
  },

  '&:not(:last-child)': {
    marginRight: '20px',
  },
}));

export const StyledDesktopSubMenu = styled(Menu)<any>(({ theme }) => ({
  '& > div': {
    borderRadius: 'unset',
    boxShadow: 'unset',

    '& ul': {
      minWidth: '250px',
      maxWidth: '350px',
      backgroundColor: theme.palette.brand.headerHighlight,

      '& li a': {
        padding: '5px',
        textTransform: 'capitalize',
        whiteSpace: 'break-spaces',
        fontWeight: '400',
        fontSize: '12px',
        textDecoration: 'none',
        textAlign: 'left',
        width: '100%',
        color: theme.palette.brand.text,
      },
    },
  },
}));
