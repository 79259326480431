import { Box, useTheme } from '@mui/material';
import PinItScript from '@orgnc/core/components/common/PinIt/PinItScript';
import { CenterLayout } from '@orgnc/core/components/primitives';
import { NewsletterSignupBlock } from '@orgnc/core/components/themes/Elements/components/blocks';
import ArticleTemplateContent from '@orgnc/core/components/themes/Elements/components/templates/ArticleTemplateContent';
import { forwardArticleRef } from '@orgnc/core/lib/themes/templates/article';
import { IArticle } from '@orgnc/core/lib/wordpress/api/adapters/articleAdapter';

const REGEXP = /^(https:\/\/platform.audience.io\/#contest\/(\d{2,})\/)$/;

const ContestsPostTemplate = forwardArticleRef((props, ref) => {
  const theme = useTheme();

  return (
    <Box ref={ref} component="article">
      <CenterLayout
        maxWidth={`${theme.breakpoints.values.xl}${theme.breakpoints.unit}`}
      >
        <div className="opscoad-WideBanner_Top"></div>
        <ArticleTemplateContent
          {...{
            ...props,
            post: replaceBlockContentsWithEmbedScripts(props.post),
          }}
        >
          <NewsletterSignupBlock />
          <PinItScript />
        </ArticleTemplateContent>
      </CenterLayout>
    </Box>
  );
});

function replaceBlockContentsWithEmbedScripts(post: IArticle) {
  // eslint-disable-next-line no-param-reassign
  post.blocks = post.blocks.map((block) => {
    const { name, originalContent = '' } = block;

    if (name === 'core/html' && REGEXP.test(originalContent)) {
      const matches = REGEXP.exec(originalContent) as any;

      // eslint-disable-next-line no-param-reassign
      block.originalContent = `
                <script>
                    window.AptivadaAsyncInit = function(){
                        window.Aptivada.init({
                            campaignId: ${matches[2]},
                            campaignType: "contest",
                            events: {
                                userCompleteEntry: function(e){},
                            }
                        })
                    }
                    </script>
                    <script src="https://campaign.aptivada.com/sdk.js"></script>
                    <div class="aptivada-campaign"></div>
            `;
    }

    return block;
  });

  return post;
}

export default ContestsPostTemplate;
