import { gql } from 'graphql-request';
import { GlobalQuery } from '@orgnc/core/lib/themes/queries';
import { IRootQuery } from '@orgnc/core/generated/graphql-operations';

export const query = gql`
  query BmgSettingsQuery {
    acfOptionsGeneral {
      generalOptions {
        bmgGeneralConfiguration {
          listenLiveVisibility
          optPublisherId
          streamWpOption
          sponsoredByVisibility
          sponsoredBrandName
          sponsoredByLogo {
            title
            altText
            mediaItemUrl
          }
          gamTunerPreroll
          gamPublisherId
        }
        listenLivePlayerButtonGroup {
          mainColour
          secondaryColour
        }
      }
    }
  }
`;

const bmgSettingsQuery: GlobalQuery<IRootQuery> = {
  name: 'bmgSettingsQuery',
  query,
};

export default bmgSettingsQuery;
