import { all } from 'redux-saga/effects';
import {
  watchSetPlayer,
  watchStart,
  watchStop,
  watchPause,
  watchResume,
  watchSetVolume,
  watchCuePointChange,
  watchSeekPosition,
  watchAdPlaybackStart,
  watchGamAdPlaybackStart,
  watchAdPlaybackComplete,
  watchAdPlaybackStop,
  watchPlay,
  watchEnd,
  watchLoadVimeo,
} from './sagas/';

/**
 * Root saga that watches for side effects.
 */
export default function* rootSaga() {
  yield all([
    watchSetPlayer(),
    watchStart(),
    watchPlay(),
    watchEnd(),
    watchStop(),
    watchPause(),
    watchResume(),
    watchSetVolume(),
    watchCuePointChange(),
    watchSeekPosition(),
    watchAdPlaybackStart(),
    watchGamAdPlaybackStart(),
    watchAdPlaybackComplete(),
    watchAdPlaybackStop(),
    watchLoadVimeo(),
  ]);
}
