/**
 * Returns whether it has been Greater than 10 minutes since lastAdPlaybackTime(ie Preroll)
 *
 * @param {Number} nowTime - current Epoch
 * @param {Number} lastAdPlaybackTime - Epoch of last Preroll start
 * @returns {boolean} shouldPlayGAMPreroll - whether we should play a GAM Preroll
 */
export default function getWhetherPlayGAMPreroll(
  nowTime: number = 0,
  lastAdPlaybackTime: number = 0
): boolean {
  const timeSinceLastPreroll = nowTime - lastAdPlaybackTime;
  const shouldPlayGAMPreroll = timeSinceLastPreroll > 10 * 60 * 1000; // Greater than 10 minutes
  // eslint-disable-next-line no-console
  console.log(
    `It has been ${timeSinceLastPreroll} milliseconds since last Preroll. ${
      shouldPlayGAMPreroll ? '' : 'NOT '
    }Playing Preroll.`
  );
  return shouldPlayGAMPreroll;
}
