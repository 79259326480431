import { useEffect } from 'react';
import { Dispatch } from 'redux';
import { loadTdSk } from '../utils/tdSdk';

interface PlayStationFromUrlProps {
  dispatch: Dispatch;
  playStationFromUrl: (station: string) => any;
}

const PlayStationFromUrl: React.FC<PlayStationFromUrlProps> = ({
  dispatch,
  playStationFromUrl,
}) => {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLAnchorElement;

      if (target.tagName === 'A' && target.href) {
        const url = target.href;
        const stationPlayLinkMatched = url.match(/str-station-([A-Za-z0-9_]+)/);

        if (stationPlayLinkMatched) {
          event.preventDefault(); // Prevent the default navigation

          loadTdSk()
            .then(() => {
              dispatch(
                playStationFromUrl(stationPlayLinkMatched[1].toUpperCase())
              );
            })
            .catch(() => {});
        }
      }
    };

    // Add event listener to the document
    document.addEventListener('click', handleClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [dispatch, playStationFromUrl]);

  return null;
};

export default PlayStationFromUrl;
