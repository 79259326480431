/**
 * Custom function used to resolve image URLs via Fastly Image Optimization service
 * @param src string
 * @param width number
 * @param quality number|null
 * @returns {string}
 */
export default function imageLoaderFastly({ src, width, quality }) {
  const url = new URL(
    src.startsWith('http') ? src : `https://example.com${src}`
  );
  url.searchParams.delete('auto');
  url.searchParams.set('format', 'auto');
  url.searchParams.set('width', width.toString());
  if (quality) {
    url.searchParams.set('quality', quality.toString());
  } else {
    url.searchParams.set('optimize', 'high');
  }
  return url.toString().replace('https://example.com', '');
}
