export default function getPlayerFromGlobal(
  player: any,
  playerType: string
): any {
  let playerNew: any = player;
  if (playerType === 'mp3player') {
    playerNew = window[player];
  } else if (playerType === 'tdplayer') {
    playerNew = window[player];
  } else if (playerType === 'omnyplayer') {
    playerNew = window[player];
  }
  return playerNew;
}
