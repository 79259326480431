import { Box, Typography } from '@mui/material';
import { FeatureLayoutPlayerButton } from '@/components/Player';
import { useGlobalQuery } from '@orgnc/core/hooks';
import bmgSettingsQuery from '@/lib/queries/bmgSettingsQuery';
import { StyledMyListenerControllerButton } from './my-listener-controller-style';

export const MyListenerController = () => {
  const { data: listenLivePlayerButtonGroup } =
    useGlobalQuery(bmgSettingsQuery);
  const mainColour =
    listenLivePlayerButtonGroup?.acfOptionsGeneral?.generalOptions
      ?.listenLivePlayerButtonGroup?.mainColour ?? '#ea252a';
  const secondaryColour =
    listenLivePlayerButtonGroup?.acfOptionsGeneral?.generalOptions
      ?.listenLivePlayerButtonGroup?.secondaryColour ?? '#ffffff';

  return (
    <Box>
      <StyledMyListenerControllerButton
        component="div"
        className="action_button"
        mainColour={mainColour}
        secondaryColour={secondaryColour}
        onClick={() => window.dispatchEvent(new Event('openListenLive'))}
        startIcon={<FeatureLayoutPlayerButton inDropDown />}
      >
        LISTEN
        <Typography
          component="span"
          sx={{ display: { xs: 'none', md: 'inline' }, font: 'inherit' }}
        >
          &nbsp;LIVE
        </Typography>
      </StyledMyListenerControllerButton>
    </Box>
  );
};
