/* eslint-disable sort-keys */
import { Dispatch } from 'redux';

let playerjs: any = null;
if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  playerjs = require('player.js');
}

export const ACTION_SET_STREAMS = 'SET_STREAMS';
export const ACTION_SET_PLAYER = 'SET_PLAYER';
export const ACTION_STATUS_CHANGE = 'PLAYER_STATUS_CHANGE';
export const ACTION_CUEPOINT_CHANGE = 'PLAYER_CUEPOINT_CHANGE';
export const ACTION_SET_VOLUME = 'PLAYER_SET_VOLUME';
export const ACTION_PLAY = 'PLAYER_PLAY';
export const ACTION_PLAY_OMNY = 'PLAYER_PLAY_OMNY';
export const ACTION_LOAD_VIMEO = 'PLAYER_LOAD_VIMEO';
export const ACTION_PAUSE = 'PLAYER_PAUSE';
export const ACTION_RESUME = 'PLAYER_RESUME';
export const ACTION_DURATION_CHANGE = 'PLAYER_DURATION_CHANGE';
export const ACTION_TIME_CHANGE = 'PLAYER_TIME_CHANGE';
export const ACTION_SEEK_POSITION = 'PLAYER_SEEK_POSITION';
export const ACTION_NOW_PLAYING_LOADED = 'PLAYER_NOW_PLAYING_LOADED';
export const ACTION_AD_PLAYBACK_START = 'PLAYER_AD_PLAYBACK_START';
export const ACTION_AD_PLAYBACK_STOP = 'PLAYER_AD_PLAYBACK_STOP';
export const ACTION_AD_PLAYBACK_COMPLETE = 'PLAYER_AD_PLAYBACK_COMPLETE';
export const ACTION_AD_PLAYBACK_ERROR = 'PLAYER_AD_PLAYBACK_ERROR';
export const ACTION_AD_BREAK_SYNCED = 'PLAYER_AD_BREAK_SYNCED';
export const ACTION_AD_BREAK_SYNCED_HIDE = 'PLAYER_AD_BREAK_SYNCED_HIDE';
export const ACTION_GAM_AD_PLAYBACK_START = 'PLAYER_GAM_AD_PLAYBACK_START';
export const ACTION_GAM_AD_PLAYBACK_COMPLETE =
  'PLAYER_GAM_AD_PLAYBACK_COMPLETE';

export const ACTION_PLAYER_START = 'PLAYER_START';
export const ACTION_PLAYER_STOP = 'PLAYER_STOP';
export const ACTION_PLAYER_END = 'ACTION_PLAYER_END';
export const ACTION_SET_PLAYER_TYPE = 'PLAYER_SET_TYPE';

export const STATUSES = {
  LIVE_BUFFERING: 'LIVE_BUFFERING',
  LIVE_CONNECTING: 'LIVE_CONNECTING',
  LIVE_FAILED: 'LIVE_FAILED',
  LIVE_PAUSE: 'LIVE_PAUSE',
  LIVE_PLAYING: 'LIVE_PLAYING',
  LIVE_RECONNECTING: 'LIVE_RECONNECTING',
  LIVE_STOP: 'LIVE_STOP',
  STATION_NOT_FOUND: 'STATION_NOT_FOUND',
  STREAM_GEO_BLOCKED: 'STREAM_GEO_BLOCKED',
};

/**
 * playbackStop action creator
 * @param {*} actionType
 * @returns {Object} action payload
 * TODO: Originally this was clearing the timeout that was
 * set by the adPlaybackStart action creator. I believe this was
 * explicitly called in order to clear the global timeout if
 * this was called directly.
 */
export function adPlaybackStop(actionType: string) {
  return {
    type: ACTION_AD_PLAYBACK_STOP,
    payload: {
      actionType,
    },
  };
}

/**
 * playbackStart action creator
 * @returns {Object} action payload
 * TODO: Originally this was setting a timeout that would
 * dispatch the adPlaybackStop action creator after 70 seconds
 */
export function adPlaybackStart() {
  return {
    type: ACTION_AD_PLAYBACK_START,
  };
}

/**
 * gamAdPlaybackStart action creator
 */
export function gamAdPlaybackStart(nowTime: number) {
  return {
    type: ACTION_GAM_AD_PLAYBACK_START,
    nowTime,
  };
}

/**
 * gamAdPlaybackComplete action creator
 */
export function gamAdPlaybackComplete() {
  return {
    type: ACTION_GAM_AD_PLAYBACK_COMPLETE,
  };
}

/**
 * adBreakSynced action creator
 */
export function adBreakSynced() {
  return {
    type: ACTION_AD_BREAK_SYNCED,
  };
}

/**
 * adBreakSyncedHide action creator
 */
export function adBreakSyncedHide() {
  return {
    type: ACTION_AD_BREAK_SYNCED_HIDE,
  };
}

/**
 * statusUpdate action creator
 * @param {String} status
 */
export function statusUpdate(status: any) {
  return {
    type: ACTION_STATUS_CHANGE,
    status,
  };
}

/**
 * cuePoint action creator
 * @param {Object} data - Payload from player event
 */
export function cuePoint(cuePointData = {}) {
  return {
    type: ACTION_CUEPOINT_CHANGE,
    cuePoint: cuePointData,
  };
}

/**
 * nowPlayingLoaded action creator
 * @param {Object} data - Payload from player event
 */
export function nowPlayingLoaded(data: any) {
  return {
    type: ACTION_NOW_PLAYING_LOADED,
    ...data,
  };
}

/**
 * durationChange action creator
 * @param {String} duration - Payload from player
 */
export function durationChange(duration: string | number) {
  return {
    type: ACTION_DURATION_CHANGE,
    duration,
  };
}

/**
 * timeChange action creator
 * @param {String} time - Payload from player
 * @param {String|null} duration - Payload from player
 */
export function timeChange(time: string | number, duration = null) {
  return {
    type: ACTION_TIME_CHANGE,
    time,
    duration,
  };
}

/**
 * Start action creator
 */
export function start() {
  return {
    type: ACTION_PLAYER_START,
  };
}

/**
 * Stop action creator
 */
export function stop() {
  return {
    type: ACTION_PLAYER_STOP,
  };
}

/**
 * Stop action creator
 */
export function end() {
  return {
    type: ACTION_PLAYER_END,
  };
}

/**
 * pause action creator
 */
export function pause() {
  return {
    type: ACTION_PAUSE,
  };
}

/**
 * resume action creator
 */
export function resume() {
  return {
    type: ACTION_RESUME,
  };
}

/**
 * initializeTdPlayer action creator
 *
 * @param {Object} player - player object reference
 */
export function setPlayer(player: any, playerType: any) {
  return {
    type: ACTION_SET_PLAYER,
    payload: { player, playerType },
  };
}

/**
 * initializeTdPlayer action creator
 *
 * @param {Object} streams - streams object reference
 */
export function setStreams(streams: any) {
  return {
    type: ACTION_SET_STREAMS,
    payload: { streams },
  };
}

/**
 * setVolume action creator
 * @param {String} volume
 */
export function setVolume(volume: number) {
  return {
    type: ACTION_SET_VOLUME,
    volume,
  };
}

/**
 * seekPosition action creator
 * @param {String} position
 */
export function seekPosition(position: number) {
  return {
    type: ACTION_SEEK_POSITION,
    position,
  };
}

/**
 * errorCatcher outputs helper console messages
 * @param {String} prefix
 */
function errorCatcher(prefix = ''): (e: any) => void {
  return (e) => {
    const { data } = e;
    const { errors = [] } = data;

    errors.forEach((error: any) =>
      // eslint-disable-next-line no-console
      console.error(`${prefix}: [${error.code}] ${error.message}`)
    );
  };
}

function getTdModules(station: any) {
  const tdmodules = [];

  tdmodules.push({
    id: 'MediaPlayer',
    playerId: 'td_container',
    techPriority: ['Html5'],
    idSync: {
      station,
    },
    geoTargeting: {
      desktop: { isActive: false },
      iOS: { isActive: false },
      android: { isActive: false },
    },
  });

  tdmodules.push({
    id: 'NowPlayingApi',
  });

  tdmodules.push({
    id: 'TargetSpot',
  });

  tdmodules.push({
    id: 'SyncBanners',
    elements: [{ id: 'sync-banner', width: 320, height: 50 }],
  });

  return tdmodules;
}

/**
 * Sets up the audio player
 *
 * @param {*} dispatch
 * @param {*} src The audio source
 */
function setUpAudioPlayer(dispatch: Dispatch, src: any) {
  (window as any).audioplayer = new Audio(src);

  (window as any).audioplayer.addEventListener('loadstart', () =>
    dispatch(statusUpdate(STATUSES.LIVE_BUFFERING))
  );
  (window as any).audioplayer.addEventListener('pause', () =>
    dispatch(statusUpdate(STATUSES.LIVE_PAUSE))
  );
  (window as any).audioplayer.addEventListener('playing', () =>
    dispatch(statusUpdate(STATUSES.LIVE_PLAYING))
  );
  (window as any).audioplayer.addEventListener('ended', () => {
    dispatch(statusUpdate(STATUSES.LIVE_STOP));
  });
  (window as any).audioplayer.addEventListener('pause', () => dispatch(end()));
  (window as any).audioplayer.addEventListener('abort', () => dispatch(end()));
  (window as any).audioplayer.addEventListener('loadedmetadata', () =>
    dispatch(durationChange((window as any).audioplayer.duration))
  );
  (window as any).audioplayer.addEventListener('timeupdate', () =>
    dispatch(timeChange((window as any).audioplayer.currentTime))
  );
}

/**
 * Sets up the omny player.
 *
 * @param {string} source The audio source file.
 */
function setUpOmnyPlayer(source: any) {
  const id = source.replace(/\W+/g, '');
  if (document.getElementById(id)) {
    return;
  }

  const iframe = document.createElement('iframe');
  iframe.id = id;
  iframe.src = source;
  document.body.appendChild(iframe);

  (window as any).omnyplayer = new playerjs.Player(iframe);
}

function getDispatchPlayParams(
  source = '',
  cueTitle = '',
  artistName = '',
  trackType = '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  image: string = ''
) {
  return {
    type: ACTION_PLAY,
    payload: {
      source,
      cueTitle,
      artistName,
      trackType,
    },
  };
}

/**
 * Low-level play action creator
 *
 * @param {string} playerType Which player to use.
 * @param {*} source Audior source or station name.
 * @param {*} type
 * @param {*} cueTitle
 * @param {*} artistName
 * @param {*} trackType
 */
const play =
  (
    playerType: any,
    source: any,
    cueTitle = '',
    artistName = '',
    trackType = '',
    image: string = ''
  ) =>
  (dispatch: Dispatch) => {
    // make sure to stop any running player.
    dispatch(stop());
    // eslint-disable-next-line no-console
    console.log('called from Play event');

    if (playerType === 'tdplayer') {
      // reset time and duration.
      dispatch(timeChange(0));
      dispatch(durationChange(0));
      // set the appropriate player.
      // dispatch(setPlayer((window as any).tdplayer, 'tdplayer'));
      dispatch(setPlayer('tdplayer', 'tdplayer'));
      // play.
      // eslint-disable-next-line no-console
      console.log('Dispatching action_play');
      dispatch(getDispatchPlayParams(source, cueTitle, artistName, trackType));
    } else if (playerType === 'mp3player') {
      if (typeof (window as any).audioplayer === 'undefined') {
        setUpAudioPlayer(dispatch, source);
      } else {
        (window as any).audioplayer.src = source;
      }
      // dispatch(setPlayer(window.audioplayer, 'mp3player'));
      dispatch(setPlayer('audioplayer', 'mp3player'));
      dispatch(
        getDispatchPlayParams(source, cueTitle, artistName, trackType, image)
      );
      dispatch(cuePoint({ type: 'track', cueTitle, artistName, image }));
    } else if (playerType === 'omnyplayer') {
      if (typeof window !== 'undefined') {
        if (typeof (window as any).audioplayer === 'undefined') {
          setUpOmnyPlayer(source);
        }

        // dispatch(setPlayer((window as any).omnyplayer, 'omnyplayer'));
        dispatch(setPlayer('omnyplayer', 'omnyplayer'));

        // all events are removed when stopping the omny player so we need to recreate them.
        (window as any).omnyplayer.on('ready', () => {
          dispatch(
            getDispatchPlayParams(source, cueTitle, artistName, trackType)
          );
          dispatch(cuePoint({ type: 'track', cueTitle, artistName }));
          dispatch(statusUpdate(STATUSES.LIVE_BUFFERING));
        });

        (window as any).omnyplayer.on('play', () =>
          dispatch(statusUpdate(STATUSES.LIVE_PLAYING))
        );
        (window as any).omnyplayer.on('pause', () =>
          dispatch(statusUpdate(STATUSES.LIVE_PAUSE))
        );
        (window as any).omnyplayer.on('ended', () =>
          dispatch(statusUpdate(STATUSES.LIVE_STOP))
        );
        (window as any).omnyplayer.on('error', () =>
          errorCatcher('Omny Error')
        );
        (window as any).omnyplayer.on(
          'timeupdate',
          ({ seconds: time, duration }: any) =>
            dispatch(timeChange(time, duration))
        );
      }
    }
  };

/**
 * Initializes the TdPlayer
 *
 * @param {*} modules
 */
export function initTdPlayer(station: any) {
  return (dispatch: Dispatch) => {
    function doInitTdPlayer() {
      let adSyncedTimeout: NodeJS.Timeout | null = null;

      function dispatchSyncedStart() {
        // hide after 35 seconds if it hasn't been hidden yet
        if (adSyncedTimeout) clearTimeout(adSyncedTimeout);
        adSyncedTimeout = setTimeout(
          () => dispatch(adBreakSyncedHide()),
          35000
        );
        dispatch(adBreakSynced());
      }

      (window as any).tdplayer = new (window as any).TDSdk({
        configurationError: errorCatcher('Configuration Error'),
        coreModules: getTdModules(station),
        moduleError: errorCatcher('Module Error'),
      });

      (window as any).tdplayer.addEventListener(
        'stream-status',
        ({ data }: any) => dispatch(statusUpdate(data.code))
      );
      (window as any).tdplayer.addEventListener(
        'list-loaded',
        ({ data }: any) => dispatch(nowPlayingLoaded(data))
      );
      (window as any).tdplayer.addEventListener(
        'track-cue-point',
        ({ data }: any) => dispatch(cuePoint(data.cuePoint || {}))
      );
      (window as any).tdplayer.addEventListener(
        'speech-cue-point',
        ({ data }: any) => dispatch(cuePoint(data.cuePoint || {}))
      );
      (window as any).tdplayer.addEventListener(
        'custom-cue-point',
        ({ data }: any) => dispatch(cuePoint(data.cuePoint || {}))
      );
      (window as any).tdplayer.addEventListener(
        'ad-break-cue-point',
        ({ data }: any) => dispatch(cuePoint(data.cuePoint || {}))
      );
      (window as any).tdplayer.addEventListener(
        'ad-break-cue-point-complete',
        () => dispatch(cuePoint())
      );
      (window as any).tdplayer.addEventListener(
        'ad-break-synced-element',
        dispatchSyncedStart
      );
      (window as any).tdplayer.addEventListener('ad-playback-start', () =>
        dispatch(adPlaybackStart())
      ); // used to dispatchPlaybackStart
      (window as any).tdplayer.addEventListener('ad-playback-complete', () =>
        dispatch(adPlaybackStop(ACTION_AD_PLAYBACK_COMPLETE))
      ); // used to dispatchPlaybackStop( ACTION_AD_PLAYBACK_COMPLETE )
      (window as any).tdplayer.addEventListener('stream-start', () =>
        dispatch(start())
      );
      (window as any).tdplayer.addEventListener('stream-stop', () =>
        dispatch(end())
      );
      (window as any).tdplayer.addEventListener('ad-playback-error', () => {
        // eslint-disable-next-line no-console
        console.log('ad-playback-error EVENT from Triton');
        if ((window as any).beforeStreamStart) {
          (window as any).beforeStreamStart(() =>
            dispatch(adPlaybackStop(ACTION_AD_PLAYBACK_ERROR))
          );
        } else {
          // eslint-disable-next-line no-console
          console.log('Dispatching GAM Preroll Start');
          const nowDate = new Date();
          dispatch(gamAdPlaybackStart(nowDate.getTime()));
        }
      });

      (window as any).tdplayer.addEventListener('player-ready', () =>
        play('tdplayer', station)(dispatch)
      );
    }
    doInitTdPlayer();
  };
}

/**
 * Action Creator for playing an audio file.
 *
 * @param {*} src The audio source.
 * @param {*} cueTitle
 * @param {*} artistName
 * @param {*} trackType
 */
export const playAudio =
  (
    src: any,
    cueTitle = '',
    artistName = '',
    trackType = 'live',
    image: string = ''
  ): any =>
  (dispatch: Dispatch) =>
    play('mp3player', src, cueTitle, artistName, trackType, image)(dispatch);

/**
 * playStation action creator
 * @param {String} station
 */
export const playStation = (station: any) => (dispatch: Dispatch) => {
  // eslint-disable-next-line no-console
  console.log(`playStation() - ${station}`);

  // Load Triton If We Have Not Done So Yet
  if (!(window as any).tdplayer) {
    initTdPlayer(station)(dispatch);
  } else {
    play('tdplayer', station)(dispatch);
  }
};

/**
 * playStationFromUrl action creator for when calling from URL.
 * We cannot play preroll so mark preroll as just played.
 * @param {String} station
 */
export const playStationFromUrl = (station: any) => (dispatch: Dispatch) => {
  if (station) {
    // eslint-disable-next-line no-console
    console.log(`playStationFromUrl() - ${station}`);
    dispatch(gamAdPlaybackComplete());
    playStation(station)(dispatch);
  }
};

/**
 * Action Creator for playing an audio file using the omnyplayer.
 *
 * @param {*} src The audio source.
 * @param {*} cueTitle
 * @param {*} artistName
 * @param {*} trackType
 */
export const playOmny =
  (src: any, cueTitle = '', artistName = '', trackType = 'live'): any =>
  (dispatch: Dispatch) =>
    play('omnyplayer', src, cueTitle, artistName, trackType)(dispatch);

export function loadVimeo(shouldKeepPriorVimeoPlayers: any) {
  return {
    type: ACTION_LOAD_VIMEO,
    payload: {
      shouldKeepPriorVimeoPlayers,
    },
  };
}

export default {
  setPlayer,
  pause,
  playAudio,
  playOmny,
  playStation,
  loadVimeo,
  resume,
  seekPosition,
  setVolume,
};
