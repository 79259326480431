import React from 'react';

interface ControlsV2Props {
  status: string;
  title?: string;
  play?: () => void;
  pause?: () => void;
  resume?: () => void;
  buttonStyle?: React.CSSProperties;
  svgStyle?: React.CSSProperties;
  isIos?: boolean;
  customTitle?: string | React.ReactNode | null;
}

const ControlsV2: React.FC<ControlsV2Props> = ({
  status,
  title = '',
  play,
  pause,
  resume,
  buttonStyle = {},
  svgStyle = {},
  isIos = false,
  customTitle = null,
}) => {
  // TODO - IOS Special Style was removed from controls.css. Remove osClass once it is determined that we will never need OS Specific logic again.
  const osClass = isIos ? '-is-ios' : '';

  return (
    <div className={`status ${status} ${osClass}`}>
      <button
        type="button"
        className="play-btn"
        onClick={() => {
          console.log('play');
          play?.();
        }}
        aria-label={`Play ${title}`}
        style={buttonStyle}
      >
        <div>
          <svg
            style={svgStyle}
            viewBox="-2 0 17 25"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16.1836 12.0055L0.910156 23.124L0.910156 0.887031L16.1836 12.0055Z" />
          </svg>
        </div>
        {customTitle ? <strong>{customTitle}</strong> : null}
      </button>

      <button
        type="button"
        className="pause-btn"
        onClick={pause}
        aria-label="Pause"
        style={buttonStyle}
      >
        <div>
          <svg
            style={svgStyle}
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="23"
            fill="none"
          >
            <rect width="4" height="23" rx="1" fill="#000" />
            <rect x="9" width="4" height="23" rx="1" fill="#000" />
          </svg>
        </div>
        {customTitle}
      </button>

      <button
        type="button"
        className="resume-btn"
        onClick={resume}
        aria-label="Resume"
        style={buttonStyle}
      >
        <div>
          <svg
            style={svgStyle}
            viewBox="-2 0 17 25"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16.1836 12.0055L0.910156 23.124L0.910156 0.887031L16.1836 12.0055Z" />
          </svg>
        </div>
        {customTitle}
      </button>

      <button type="button" className="loading-btn" aria-label="Loading">
        <div className="loading" />
      </button>
    </div>
  );
};

export default ControlsV2;
