import { getDefaultTheme } from '../default-theme/default-theme';

export default getDefaultTheme({
  color: {
    primary: '#FA403C',
    secondary: '#000000',
    error: '#ea252a',
    brand: {
      main: '#F1F1F1',
      text: '#000000',
    },
  },
});
