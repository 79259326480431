import HashtagLabs from '@/components/HashtagLabs';
import RegisteredBlockType from '@orgnc/core/components/blocks/Blocks/types';
import ElementsHomepageTemplateComponent from '@orgnc/core/components/themes/Elements/components/templates/Page/Homepage';
import { IPageTemplateProps } from '@orgnc/core/lib/themes/templates/page';

const eagerLoadArticleBlocks = (
  page: IPageTemplateProps['page'],
  numberOfBlocks: number
): IPageTemplateProps['page'] => {
  let modifiedBlocksCount = 0;
  return {
    ...page,
    blocks: page.blocks.map((block) => {
      if (block.name !== RegisteredBlockType.AcfArticleListBlock) {
        return block;
      }

      if (modifiedBlocksCount > numberOfBlocks) {
        return block;
      }

      modifiedBlocksCount += 1;
      return { ...block, hasImageLazyLoad: false };
    }),
  };
};

const NUMBER_OF_EAGER_BLOCKS = 3;

const HomePageTemplate = (props: IPageTemplateProps) => {
  const page = eagerLoadArticleBlocks(props.page, NUMBER_OF_EAGER_BLOCKS);
  return (
    <>
      <HashtagLabs />
      <ElementsHomepageTemplateComponent {...props} page={page} />
    </>
  );
};

export default HomePageTemplate;
