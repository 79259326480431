import { call, takeLatest, select } from 'redux-saga/effects';
import { PlayerState } from '@/redux/reducers/player';
import { getPlayerFromGlobal } from '@/redux/utilities';
import { ACTION_SEEK_POSITION } from '../../actions/player';

/**
 * @function yieldSeekPosition
 * Generator runs whenever ACTION_SEEK_POSITION is dispatched
 *
 * @param {Object} action Dispathed action
 * @param {Object} action.position Position from dispatched action
 */
function* yieldSeekPosition({ position }: any) {
  // Get player from state
  const playerStore: PlayerState = yield select(({ player }) => player);

  // Destructure player and type
  const { playerType } = playerStore;
  let { player } = playerStore;
  player = getPlayerFromGlobal(player, playerType);

  // If mp3player
  if (playerType === 'mp3player') {
    player.currentTime = position;

    // If omnyplayer
  } else if (playerType === 'omnyplayer') {
    yield call([player, 'setCurrentTime'], position);
  }
}

/**
 * @function watchSeekPosition
 * Generator used to bind action and callback
 */
export default function* watchSetVolume() {
  yield takeLatest([ACTION_SEEK_POSITION], yieldSeekPosition);
}
