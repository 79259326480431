export enum SiteType {
  ESPNSWFL = 'espnswfl',
  B1027VGS = '1027vgs',
  AUGUSTATODAY = 'augustatoday',
  COYOTECOUNTRYLV = 'coyotecountrylv',
  FOXY99 = 'foxy99',
  WKML = 'wkml',
  PLAYJACKRADIO = 'playjackradio',
  B1077THEBOUNCE = '1077thebounce',
  MYKISSRADIO = 'mykissradio',
  SUNNY943 = 'sunny943',
  WRAT = 'wrat',
  MAGIC983 = 'magic983',
  WDHAFM = 'wdhafm',
  WJRZ = 'wjrz',
  WMTRAM = 'wmtram',
  WTMRRADIO = 'wtmrradio',
  WGAC = 'wgac',
  KICKS99 = 'kicks99',
  HD983 = 'hd983',
  HOTAUGUSTA = 'hotaugusta',
  SUNNY1027 = 'sunny1027',
  ILOVEBOBFM = 'ilovebobfm',
  WRIF = 'wrif',
  BACKSTAGECOUNTRY = 'backstagecountry',
  CHECKPOINTXP = 'checkpointxp',
  DAVEANDCHUCKTHEFREAK = 'daveandchuckthefreak',
  PODCASTRADIOUS = 'podcastradious',
  ROARDETROIT = 'roardetroit',
  THEANDIESUMMERSSHOW = 'theandiesummersshow',
  MUSTHAVESANDFUNFINDS = 'musthavesandfunfinds',
}

export interface IGetDefaultThemeParams {
  color: {
    primary: string;
    secondary: string;
    brand: {
      main: string;
      text: string;
      highlight?: string;
      listenLive?: string;
    };
    text?: {
      primary?: string;
      secondary?: string;
    };
    error: string;
  };
  font?: {
    base: string;
    sans: string;
    serif: string;
  };
}

export interface ISTNPlayerInfoParams {
  key: string;
  cid: string;
  fk: string;
  type: string;
}
