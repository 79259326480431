import HashtagLabs from '@/components/HashtagLabs';
import HashtagLabsAd from '@/components/HashtagLabsAd';
import NewsletterPlaceholder from '@/components/NewsletterPlaceholder';
import NewsletterScript from '@/components/NewsletterScript';
import { Box, useTheme } from '@mui/material';
import RegisteredBlockType from '@orgnc/core/components/blocks/Blocks/types';
import PinItScript from '@orgnc/core/components/common/PinIt/PinItScript';
import { CenterLayout } from '@orgnc/core/components/primitives';
import { NewsletterSignupBlock } from '@orgnc/core/components/themes/Elements/components/blocks';
import ArticleTemplateContent from '@orgnc/core/components/themes/Elements/components/templates/ArticleTemplateContent';
import { useSiteSettings } from '@orgnc/core/hooks';
import { forwardArticleRef } from '@orgnc/core/lib/themes/templates/article';
import { IArticle } from '@orgnc/core/lib/wordpress/api/adapters/articleAdapter';
import { getDomainThemeId } from '../../../themes';
import { SiteType } from '../../../themes/type';
import useCanonicalDomain from '../../../utils/useCanonicalDomain';

const injectAds = (
  article: IArticle,
  siteType: SiteType | string,
  stnInfo:
    | {
        stnBarkerId: string;
        stnCid: string;
        stnInarticleId: string;
        stnPosition: string;
      }
    | undefined
): IArticle => {
  const {
    // featureVideoProvider,
    stnBarkerId,
    // stnCategories,
    stnCid,
    stnInarticleId,
    stnPosition,
  } = stnInfo ?? {};
  const addAdAfterEveryThirdBlock = (
    blocks: IArticle['blocks']
  ): IArticle['blocks'] => {
    return blocks.reduce((updatedBlocks, currentBlock, index) => {
      const isAdPlacementEligible =
        (index + 1) % 3 === 0 &&
        currentBlock.name !== RegisteredBlockType.CoreHeadingBlock;
      const isSTNPlacementEligible =
        (index === 1 &&
          currentBlock.name !== RegisteredBlockType.CoreHeadingBlock) ||
        (index === 2 &&
          blocks[1].name === RegisteredBlockType.CoreHeadingBlock);

      const updatedBlock = { ...currentBlock, order: updatedBlocks.length };

      const adBlock = isAdPlacementEligible
        ? {
            name: 'acf/htl-ad',
            order: updatedBlocks.length + 1,
            unit: 'WideBanner_Scroll',
            hasBaseHeight: false,
            attributes: {},
            innerBlocks: [],
          }
        : null;

      const stnPlayerBlock = isSTNPlacementEligible
        ? {
            name: 'acf/stn-player',
            attributes: {
              name: 'acf/stn-player',
              className: '',
              data: {
                key: stnBarkerId ?? 'cmD5bzJfc4-3589519-11378',
                cid: stnCid ?? '11378',
                fk: stnInarticleId ?? 'cmD5bzJfc4',
                type: stnPosition ?? 'float',
              },
              mode: 'edit',
            },
            innerBlocks: [],
            order: updatedBlocks.length + 1,
          }
        : null;

      // Add the current block, optional ad block, and optional stn-player block
      return [
        ...updatedBlocks,
        updatedBlock,
        ...(adBlock ? [adBlock] : []),
        ...(stnPlayerBlock ? [stnPlayerBlock] : []),
      ];
    }, [] as IArticle['blocks']);
  };

  const newBlocks = addAdAfterEveryThirdBlock(article.blocks);

  return {
    ...article,
    blocks: newBlocks,
  };
};

const ArticleTemplate = forwardArticleRef((props, ref) => {
  const theme = useTheme();
  const domain = useCanonicalDomain();
  const setting = useSiteSettings();

  const { stnPlayerSettings } = setting.data as any;

  let extendedProps = {
    ...props,
    post: injectAds(props.post, getDomainThemeId(domain), stnPlayerSettings),
    sidebarContent: <HashtagLabsAd unit={'HalfPage_RightRail'} />,
    beforeContentSlot: <HashtagLabsAd unit={'WideBanner_Scroll'} />,
    afterContentSlot: (
      <>
        <NewsletterPlaceholder />
        <NewsletterScript />
        <HashtagLabsAd unit={'WideBanner_Scroll'} />
      </>
    ),
  };

  const addTargetBlankToLinks = (content: string): string => {
    if (!content) return content;

    const baseDomain = domain ?? '';

    // Create a function to extract the base domain from a hostname
    const getBaseDomain = (hostname: string): string => {
      // Extract the base domain from hostname
      const parts = hostname.split('.');
      if (parts.length >= 2) {
        // Take the last two parts of the domain
        return parts.slice(-2).join('.');
      }
      return hostname;
    };

    // Regex to match <a> tags and extract href attribute
    return content.replace(
      /<a\s+([^>]*)href=['"](https?:\/\/[^'"]+)['"]([^>]*)>/gi,
      (match, before, href, after) => {
        try {
          const url = new URL(href);

          // Check if the link's base domain matches our base domain
          const linkBaseDomain: string = getBaseDomain(url.hostname);
          const siteBaseDomain: string = getBaseDomain(baseDomain);

          const isExternal: boolean = linkBaseDomain !== siteBaseDomain;

          // Only add target="_blank" to external links
          if (isExternal && !match.includes('target="_blank"')) {
            return `<a ${before}href="${href}"${after} target="_blank">`;
          }

          return match;
        } catch (error) {
          // If URL parsing fails, return the original match
          return match;
        }
      }
    );
  };

  // Recursive function to modify blocks and their innerBlocks
  const processBlockRecursively = (
    block: IArticle['blocks'][0]
  ): IArticle['blocks'][0] => {
    // Process current block's originalContent if it exists
    const processedBlock = { ...block };

    if (
      'originalContent' in processedBlock &&
      typeof processedBlock.originalContent === 'string'
    ) {
      processedBlock.originalContent = addTargetBlankToLinks(
        processedBlock.originalContent
      );
    }

    // Process innerBlocks recursively if they exist
    if (
      processedBlock.innerBlocks &&
      Array.isArray(processedBlock.innerBlocks) &&
      processedBlock.innerBlocks.length > 0
    ) {
      processedBlock.innerBlocks = processedBlock.innerBlocks.map(
        (innerBlock) => processBlockRecursively(innerBlock)
      );
    }

    return processedBlock;
  };

  const modifiedBlocks: IArticle['blocks'] = extendedProps.post.blocks.map(
    (block) => processBlockRecursively(block)
  );

  extendedProps = {
    ...extendedProps,
    post: {
      ...extendedProps.post,
      blocks: modifiedBlocks,
    },
  };

  return (
    <Box ref={ref} component="article">
      <HashtagLabs />
      <CenterLayout
        maxWidth={`${theme.breakpoints.values.xl}${theme.breakpoints.unit}`}
        sx={{
          '& a': {
            overflowWrap: 'break-word',
            maxWidth: '100%',
            display: 'inline-block',
          },
        }}
      >
        <HashtagLabsAd
          unit={'WideBanner_Top'}
          sx={{ mt: { xs: 8, sm: 2 }, height: 250 }}
        />
        <Box>
          <ArticleTemplateContent
            {...{
              ...extendedProps,
            }}
          >
            <NewsletterSignupBlock />
            <PinItScript />
          </ArticleTemplateContent>
        </Box>
        <HashtagLabsAd unit={'WideBanner_Scroll'} />
      </CenterLayout>
    </Box>
  );
});

export default ArticleTemplate;
