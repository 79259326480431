import React, { useEffect, useRef } from 'react';
import {useRouter} from "next/router";
import {RenderBlockProps} from "@orgnc/core/components/blocks/Blocks/helpers/RenderBlock";
import {BlockFragment} from "@orgnc/core/lib/wordpress/api/fragments/blocksFragment";

export function getParamsStnEmbed(dataset: DOMStringMap) {
  return {
    fk: dataset.fk,
    cid: dataset.cid,
    videokey: dataset.key,
    type: dataset.type,
  };
}

const eeIsWhizStnVideo = () => {
  let whizPos = null;

  if (typeof window !== 'undefined') {
    whizPos = navigator.userAgent.toLowerCase().indexOf('whiz');
    if (whizPos === -1) {
      whizPos = false;
    }

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('whiz')) {
      whizPos = 1;
    }
  }

  return whizPos !== false;
};

interface STNVideoPlayerProps {
  block: {
    attributes: {
      data: {
        fk?: string;
        cid?: string;
        key?: string;
        type?: string;
      };
    };
  };
}

const STNVideoPlayer: React.FunctionComponent<RenderBlockProps> = ({ block: props }) => {
  const block = props as unknown as BlockFragment<STNVideoPlayerProps>;
  const {
    fk = '8EjYDtFS',
    cid = '12574',
    key: videokey = '',
    type = 'float',
  } = block.attributes.data;
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const renderSTNVideoMobile = () => {
      if (videokey.toLowerCase() === 'none') {
        return;
      }

      const stndiv = document.createElement('div');
      stndiv.className = `s2nPlayer k-${fk}`;
      stndiv.setAttribute('data-type', type);

      const stnBarkerScript = document.createElement('script');
      stnBarkerScript.type = 'text/javascript';
      stnBarkerScript.src = videokey
        ? `//embed.sendtonews.com/player3/embedcode.js?SC=${videokey}&cid=${cid}&offsetx=0&offsety=75&floatwidth=400&floatposition=bottom-right`
        : `//embed.sendtonews.com/player3/embedcode.js?fk=${fk}&cid=${cid}&offsetx=0&offsety=75&floatwidth=400&floatposition=bottom-right`;
      stnBarkerScript.setAttribute('data-type', 's2nScript');

      containerRef.current?.appendChild(stndiv);
      containerRef.current?.appendChild(stnBarkerScript);
    };

    if (type === 'featured' && !eeIsWhizStnVideo()) {
      const featuredDiv = document.querySelector('.post-thumbnail');
      if (featuredDiv) {
        featuredDiv.innerHTML = '';
        featuredDiv.classList.add(
          'stn-video-thumbnail',
          'thumbnail-video-jacapp',
          'stn-video-jacapp'
        );
        renderSTNVideoMobile();
        const postThumbnailFeaturedCaption = document.querySelector(
          '.description .post-thumbnail-caption'
        );
        if (postThumbnailFeaturedCaption) {
          (postThumbnailFeaturedCaption as HTMLElement).style.display = 'none';
        }
      } else {
        const description = document.querySelector('.description');
        if (description) {
          const thumbnailDiv1 = document.createElement('div');
          thumbnailDiv1.className =
            'post-thumbnail featured-media stn-video-thumbnail thumbnail-video-jacapp stn-video-jacapp';
          const thumbnailDiv2 = document.createElement('div');
          thumbnailDiv2.className = 'post-thumbnail-wrapper';
          thumbnailDiv2.appendChild(thumbnailDiv1);
          description.insertBefore(thumbnailDiv2, description.firstChild);
          renderSTNVideoMobile();
        }
      }
    } else {
      renderSTNVideoMobile();
    }
  }, [fk, cid, videokey, type]);

  // Return null if the _noext query parameter is present
  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { _noext } = router.query;
  if (_noext !== undefined) {
    return null;
  }

  return <div ref={containerRef} className="stnplayer"></div>;
};

export default STNVideoPlayer;
