/* eslint-disable sort-keys */
import {
  livePlayerLocalStorage,
  getInitialStation,
  parseVolume,
} from '../utilities';

// Player action imports
import {
  ACTION_SET_STREAMS,
  ACTION_SET_PLAYER,
  ACTION_STATUS_CHANGE,
  ACTION_CUEPOINT_CHANGE,
  ACTION_SET_VOLUME,
  ACTION_PAUSE,
  ACTION_RESUME,
  ACTION_DURATION_CHANGE,
  ACTION_TIME_CHANGE,
  ACTION_SEEK_POSITION,
  ACTION_NOW_PLAYING_LOADED,
  ACTION_AD_PLAYBACK_START,
  ACTION_AD_PLAYBACK_COMPLETE,
  ACTION_AD_PLAYBACK_ERROR,
  ACTION_AD_BREAK_SYNCED,
  ACTION_AD_BREAK_SYNCED_HIDE,
  ACTION_GAM_AD_PLAYBACK_START,
  ACTION_GAM_AD_PLAYBACK_COMPLETE,
  ACTION_SET_PLAYER_TYPE,
  STATUSES,
  ACTION_PLAY,
} from '../actions/player';
import getWhetherPlayGAMPreroll from '../utilities/player/getWhetherPlayGAMPreroll';

const streams = [{}];

// Helper object to reset some state
// Good for re-use in the reducers
const adReset = {
  adPlayback: false,
  adSynced: false,
  gamAdPlayback: false,
  gamAdPlaybackStop: false,
};

// Define the state type
export interface PlayerState {
  audio: string;
  trackType: string;
  cuePoint: boolean;
  time: number;
  duration: number;
  lastAdPlaybackTime: number;
  player: any;
  playerType: string;
  userInteraction: boolean;
  status: string;
  station: string;
  volume: number;
  streams: any[];
  adPlayback: boolean;
  adSynced: boolean;
  gamAdPlayback: boolean;
  gamAdPlaybackStop: boolean;
  songs?: any[];
}

// Default state object
export const DEFAULT_STATE: PlayerState = {
  audio: 'Sample Audio',
  trackType: 'Sample Track',
  cuePoint: false,
  time: 2000,
  duration: 120,
  lastAdPlaybackTime: 10,
  player: {},
  playerType: 'omny',
  userInteraction: false,
  status: STATUSES.LIVE_STOP,
  station:
    (getInitialStation(streams) || streams[0] || {}).stream_call_letters || '',
  volume: parseVolume(livePlayerLocalStorage.getItem('volume') || 100),
  streams,
  ...adReset,
};

// Reducer
// eslint-disable-next-line @typescript-eslint/default-param-last
function reducer(state: PlayerState = DEFAULT_STATE, action: any): PlayerState {
  switch (action.type) {
    case ACTION_SET_STREAMS: {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { streams } = action.payload;
      return {
        ...state,
        station:
          (getInitialStation(streams) || streams[0] || {})
            .stream_call_letters || '',
        streams,
      };
    }
    case ACTION_SET_PLAYER: {
      const { playerType, player } = action.payload;
      return {
        ...state,
        playerType,
        player,
      };
    }
    case ACTION_PLAY: {
      const { source, trackType } = action.payload;
      const newState = {
        ...state,
        station: '',
        audio: '',
        trackType: '',
      };

      if (state.playerType === 'tdplayer') {
        newState.station = source;
      } else if (['mp3player', 'omnyplayer'].includes(state.playerType)) {
        newState.audio = source;
        newState.trackType = trackType;
      }

      return newState;
    }
    case ACTION_PAUSE:
    case ACTION_RESUME:
      return {
        ...state,
        ...adReset,
      };

    case ACTION_STATUS_CHANGE:
      return {
        ...state,
        status: action.status,
      };

    case ACTION_SET_VOLUME: {
      const volume = parseVolume(action.volume);
      return {
        ...state,
        volume,
      };
    }

    case ACTION_CUEPOINT_CHANGE:
      return {
        ...state,
        ...adReset,
        cuePoint: action.cuePoint,
        userInteraction: false,
      };

    case ACTION_DURATION_CHANGE:
      return {
        ...state,
        duration: +action.duration,
      };

    case ACTION_TIME_CHANGE: {
      const override: Partial<PlayerState> = {};
      const { time, duration } = action;

      if (action.time) {
        override.time = +time;
      }
      if (action.duration) {
        override.duration = +duration;
      }

      return {
        ...state,
        ...override,
      };
    }

    case ACTION_SEEK_POSITION: {
      const { playerType } = state;
      const stateUpdate: Partial<PlayerState> = {
        userInteraction: true,
      };

      if (playerType === 'mp3player' || playerType === 'omnyplayer') {
        stateUpdate.time = +action.position;
      }
      return {
        ...state,
        ...stateUpdate,
      };
    }

    case ACTION_NOW_PLAYING_LOADED:
      return {
        ...state,
        songs: action.list,
      };

    case ACTION_AD_PLAYBACK_START:
      return {
        ...state,
        adPlayback: true,
      };

    case ACTION_GAM_AD_PLAYBACK_START: {
      const { lastAdPlaybackTime } = state;
      const shouldPlayGAMPreroll = getWhetherPlayGAMPreroll(
        action.nowTime,
        lastAdPlaybackTime
      );

      return {
        ...state,
        gamAdPlayback: shouldPlayGAMPreroll,
        gamAdPlaybackStop: !shouldPlayGAMPreroll,
      };
    }

    case ACTION_AD_PLAYBACK_ERROR: {
      // eslint-disable-next-line no-console
      console.log('Preroll complete but unsuccessful');
      return {
        ...state,
        adPlayback: false,
        gamAdPlayback: false,
        gamAdPlaybackStop: true,
      };
    }

    case ACTION_AD_PLAYBACK_COMPLETE:
    case ACTION_GAM_AD_PLAYBACK_COMPLETE: {
      // eslint-disable-next-line no-console
      console.log('Successful Preroll complete - updating time stamp');
      const nowDate = new Date();
      return {
        ...state,
        adPlayback: false,
        gamAdPlayback: false,
        gamAdPlaybackStop: true,
        lastAdPlaybackTime: nowDate.getTime(),
      };
    }

    case ACTION_AD_BREAK_SYNCED:
      return {
        ...state,
        ...adReset,
        adSynced: true,
      };

    case ACTION_AD_BREAK_SYNCED_HIDE:
      return {
        ...state,
        ...adReset,
      };

    case ACTION_SET_PLAYER_TYPE:
      return {
        ...state,
        playerType: action.payload,
      };

    default:
      return state;
  }
}

export default reducer;
