import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import {
  ACTION_AD_PLAYBACK_ERROR,
  ACTION_GAM_AD_PLAYBACK_COMPLETE,
} from '@/redux/actions/player';
import { getDomain, isIOS } from '@/lib/utilities';

interface GamPrerollProps {
  adPlaybackStop: (actionType: string) => void;
  gamTunerPreroll: string | '';
}

interface GamPrerollState {
  startedPrerollFlag: boolean;
  playingPrerollFlag: boolean;
  isFinalized: boolean;
}

class GamPreroll extends PureComponent<GamPrerollProps, GamPrerollState> {
  private adsManager: any;

  private adsLoader: any;

  private adDisplayContainer: any;

  private videoContent: HTMLVideoElement | null;

  private container: HTMLElement | null;

  onResize: () => void;

  constructor(props: any) {
    // eslint-disable-next-line no-console
    console.log('GamPreroll Constructor');
    super(props);
    this.state = {
      startedPrerollFlag: false,
      playingPrerollFlag: false,
      isFinalized: false,
    };

    this.adsManager = null;
    this.adsLoader = null;
    this.adDisplayContainer = null;
    this.videoContent = null;
    this.container = document.getElementById('gam_ad_container');

    this.doClaimVideoElement = this.doClaimVideoElement.bind(this);
    this.doPreroll = this.doPreroll.bind(this);
    this.startPreroll = this.startPreroll.bind(this);
    this.onAdsManagerLoaded = this.onAdsManagerLoaded.bind(this);
    this.onAdEvent = this.onAdEvent.bind(this);
    this.onAdError = this.onAdError.bind(this);
    this.initializeAds = this.initializeAds.bind(this);
    this.finalize = this.finalize.bind(this);

    this.onResize = this.handleResize.bind(this);
    this.updateSize = this.updateSize.bind(this);
  }

  handleResize() {
    this.updateSize();
  }

  updateSize() {
    if (this.adsManager) {
      const containerElement = document.getElementById('gamPrerollAdContainer');
      if (containerElement) {
        const width = containerElement.clientWidth;
        // Height Showing as 0 so compute... const height = containerElement.clientHeight;
        const height = (width / 640) * 480;
        this.adsManager.resize(
          width,
          height,
          (window as any).google.ima.ViewMode.NORMAL
        );
      }
    }
  }

  startPreroll(adUnitID: string, cdomain: string) {
    const { startedPrerollFlag } = this.state;
    if (startedPrerollFlag) return;

    if (this.getIsIMALoaded()) {
      // All is well - do nothing
    } else {
      // eslint-disable-next-line no-console
      console.log(`Unexpected Call To StartPreroll() Without IMA Loaded`);
      this.finalize();
      return;
    }
    // eslint-disable-next-line no-console
    console.log('GAM: Now calling setUpIMA');
    this.videoContent = document.getElementById(
      'gamPrerollContentElement'
    ) as HTMLVideoElement;
    this.setUpIMA(adUnitID, cdomain);

    // Mark State
    this.setState({
      startedPrerollFlag: true,
      playingPrerollFlag: false,
      isFinalized: false,
    });
  }

  setUpIMA(adUnitID: string, cdomain: string) {
    // eslint-disable-next-line no-console
    console.log('GAM: Inside setUpIMA before createAdDisplayContainer');
    // Create the ad display container.
    this.createAdDisplayContainer();
    // eslint-disable-next-line no-console
    console.log('GAM: Inside setUpIMA after createAdDisplayContainer');
    // Create ads loader.
    this.adsLoader = new (window as any).google.ima.AdsLoader(
      this.adDisplayContainer
    );
    // Listen and respond to ads loaded and error events.
    this.adsLoader.addEventListener(
      (window as any).google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
      this.onAdsManagerLoaded,
      false
    );
    this.adsLoader.addEventListener(
      (window as any).google.ima.AdErrorEvent.Type.AD_ERROR,
      this.onAdError,
      false
    );

    // An event listener to tell the SDK that our content video
    // is completed so the SDK can play any post-roll ads.
    const contentEndedListener = () => {
      this.adsLoader.contentComplete();
    };
    this.videoContent!.onended = contentEndedListener;

    // Request video ads.
    // eslint-disable-next-line no-console
    console.log('Requesting GAM Video Ad');
    const adsRequest = new (window as any).google.ima.AdsRequest();
    adsRequest.adTagUrl = `https://pubads.g.doubleclick.net/gampad/live/ads?iu=${adUnitID}&description_url=[placeholder]&tfcd=0&npa=0&sz=640x480&cust_params=cdomain%3D${cdomain}&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=`;
    // https://pubads.g.doubleclick.net/gampad/live/ads?iu=/26918149/WBZ_PreRoll&description_url=[placeholder]&tfcd=0&npa=0&sz=640x480&cust_params=cdomain%3D985thesportshub.com&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=

    this.adsLoader.requestAds(adsRequest);
  }

  createAdDisplayContainer() {
    // We assume the adContainer is the DOM id of the element that will house
    // the ads.
    this.adDisplayContainer = new (window as any).google.ima.AdDisplayContainer(
      document.getElementById('gamPrerollAdContainer'),
      this.videoContent
    );
  }

  initializeAds() {
    // Put In Delayed Guard
    setTimeout(() => {
      const { playingPrerollFlag } = this.state;
      if (!playingPrerollFlag) {
        // eslint-disable-next-line no-console
        console.log(
          `Detected That Preroll Is Not Playing After 3 Seconds. Finalizing Preroll`
        );
        this.finalize();
      }
    }, 3000);

    // Mark State
    this.setState({ playingPrerollFlag: true });

    // Initialize the container. Must be done via a user action on mobile devices.
    this.videoContent!.load();
    this.adDisplayContainer.initialize();

    try {
      // Initialize the ads manager. Ad rules playlist will start at this time.
      this.adsManager.init(
        640,
        480,
        (window as any).google.ima.ViewMode.NORMAL
      );
      this.updateSize();
      // Call play to start showing the ad. Single video and overlay ads will
      // start at this time; the call will be ignored for ad rules.
      this.adsManager.start();
    } catch (adError) {
      // An error may be thrown if there was a problem with the VAST response.
      this.finalize();
    }
  }

  onAdsManagerLoaded(adsManagerLoadedEvent: any) {
    // Get the ads manager.
    const adsRenderingSettings = new (
      window as any
    ).google.ima.AdsRenderingSettings();

    adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;

    // videoContent should be set to the content video element.
    this.adsManager = adsManagerLoadedEvent.getAdsManager(
      this.videoContent,
      adsRenderingSettings
    );

    // Add listeners to the required events.
    this.adsManager.addEventListener(
      (window as any).google.ima.AdErrorEvent.Type.AD_ERROR,
      this.onAdError
    );

    this.adsManager.addEventListener(
      (window as any).google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
      this.onAdEvent
    );

    // Listen to any additional events, if necessary.
    this.adsManager.addEventListener(
      (window as any).google.ima.AdEvent.Type.LOADED,
      this.onAdEvent
    );

    this.adsManager.addEventListener(
      (window as any).google.ima.AdEvent.Type.STARTED,
      this.onAdEvent
    );

    this.adsManager.addEventListener(
      (window as any).google.ima.AdEvent.Type.COMPLETE,
      this.onAdEvent
    );

    this.adsManager.addEventListener(
      (window as any).google.ima.AdEvent.Type.CLICK,
      this.onAdEvent
    );

    this.adsManager.addEventListener(
      (window as any).google.ima.AdEvent.Type.VIDEO_CLICKED,
      this.onAdEvent
    );

    this.adsManager.addEventListener(
      (window as any).google.ima.AdEvent.Type.VIDEO_ICON_CLICKED,
      this.onAdEvent
    );

    this.initializeAds();
  }

  onAdEvent(adEvent: any) {
    const wrapperEl = document.getElementById('gamPrerollWrapper');

    // Retrieve the ad from the event. Some events (e.g. ALL_ADS_COMPLETED)
    // don't have ad object associated.
    const ad = adEvent.getAd();
    // eslint-disable-next-line no-console
    console.log(`IMA Event - '${adEvent.type}'`);
    switch (adEvent.type) {
      case (window as any).google.ima.AdEvent.Type.LOADED:
        // eslint-disable-next-line no-console
        console.log(`FOR DEBUG - IMA Ad Loaded Event.`);
        this.updateSize();
        // This is the first event sent for an ad - it is possible to
        // determine whether the ad is a video ad or an overlay.
        if (ad.isLinear()) {
          // eslint-disable-next-line no-console
          console.log(`Ad Is Linear.`);
        } else {
          // eslint-disable-next-line no-console
          console.log(`Ad Is Not Linear - Playing.`);
          this.videoContent?.play();
        }
        break;
      case (window as any).google.ima.AdEvent.Type.STARTED:
        // This event indicates the ad has started - the video player
        // can adjust the UI, for example display a pause button and
        // remaining time.
        if (wrapperEl) {
          wrapperEl.classList.add('gampreroll-shade');
        }
        break;
      // case (window as any).google.ima.AdEvent.Type.COMPLETE:
      case (window as any).google.ima.AdEvent.Type.CLICK:
      case (window as any).google.ima.AdEvent.Type.VIDEO_CLICKED:
      case (window as any).google.ima.AdEvent.Type.VIDEO_ICON_CLICKED:
      case (window as any).google.ima.AdEvent.Type.ALL_ADS_COMPLETED:
        // This event indicates that ALL Ads have finished.
        // This event was seen emitted from a Google example ad upon pressing a "Skip Ad" button.
        this.finalize();
        break;
      default:
        // eslint-disable-next-line no-console
        console.log(`Unhandled IMA Event - '${adEvent.type}'`);
        break;
    }
  }

  onAdError(adErrorEvent: any) {
    // Handle the error logging.
    // eslint-disable-next-line no-console
    console.log(adErrorEvent.getError());
    this.finalize();
  }

  componentDidMount() {
    (window as any).addEventListener('resize', this.onResize);
    if (isIOS()) {
      this.doClaimVideoElement();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  doClaimVideoElement() {
    // eslint-disable-next-line no-console
    console.log('Claiming Video Element For IOS');
    const vidElement = document.getElementById(
      'gamPrerollContentElement'
    ) as HTMLVideoElement;
    if (vidElement) {
      (async () => {
        try {
          await vidElement.play();
          await vidElement.pause();
          // eslint-disable-next-line no-empty
        } catch (err) {}
      })();
    }
  }

  doPreroll() {
    const { isFinalized } = this.state;
    if (isFinalized) {
      // eslint-disable-next-line no-console
      console.log('Not Re-Playing Preroll');
      return;
    }

    const cdomain = getDomain();
    // eslint-disable-next-line no-console
    console.log(
      'domain: ',
      cdomain,
      ' and gamTunerPreroll: ',
      this.props.gamTunerPreroll
    );

    if (this.props.gamTunerPreroll) {
      // Play the preroll
      this.startPreroll(this.props.gamTunerPreroll.trim(), cdomain);
    } else {
      // eslint-disable-next-line no-console
      console.log(`NOT playing GAM Preroll - no tunerpreroll.unitId found`);
      this.finalize();
    }
  }

  componentWillUnmount() {
    (window as any).removeEventListener('resize', this.onResize);
    this.finalize();
  }

  // eslint-disable-next-line class-methods-use-this
  getIsIMALoaded() {
    return (
      (window as any).google &&
      (window as any).google.ima &&
      (window as any).google.ima.AdsLoader
    );
  }

  finalize() {
    // eslint-disable-next-line no-console
    console.log('GAM Preroll Finalize()');

    if (this.adsManager) {
      this.adsManager.destroy();
    }

    const { isFinalized, playingPrerollFlag } = this.state;
    if (!isFinalized) {
      // Mark State
      this.setState({
        startedPrerollFlag: false,
        playingPrerollFlag: false,
        isFinalized: true,
      });
      // eslint-disable-next-line no-console
      console.log(
        `GAM Preroll Actually Finalizing - playingPrerollFlag: ${playingPrerollFlag}`
      );
      // Call Player Action
      const { adPlaybackStop } = this.props;
      // If we even started a preroll, pretend it was a success
      if (playingPrerollFlag) {
        adPlaybackStop(ACTION_GAM_AD_PLAYBACK_COMPLETE);
      } else {
        adPlaybackStop(ACTION_AD_PLAYBACK_ERROR);
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    let children = null;
    if (isIOS()) {
      const width = (window as any).document.body.clientWidth;
      const height = (width / 640) * 480;
      const topMargin = ((window as any).innerHeight - height) / 2;
      children = (
        <div id="gamPrerollWrapper" className="gampreroll-wrapper -active">
          <div id="gamPrerollContent" style={{ marginTop: topMargin }}>
            <video
              id="gamPrerollContentElement"
              width={width}
              height={height}
              playsInline
            >
              <track
                src="captions_en.vtt"
                kind="captions"
                srcLang="en"
                label="english_captions"
              />
            </video>
          </div>
          <div id="gamPrerollAdContainer" className="gam-preroll-player" />
        </div>
      );
    }

    children = (
      <div id="gamPrerollWrapper" className="gampreroll-wrapper -active">
        <div id="gamPrerollContent">
          <video id="gamPrerollContentElement">
            <track
              src="captions_en.vtt"
              kind="captions"
              srcLang="en"
              label="english_captions"
            />
          </video>
        </div>
        <div id="gamPrerollAdContainer" className="gam-preroll-player" />
      </div>
    );

    if (this.container) {
      return ReactDOM.createPortal(children, this.container);
    }
    return null;
  }
}

export default GamPreroll;
