import ErrorBoundary from '@/components/ErrorBoundary';
import { isAudioAdOnly, isIOS } from '@/lib/utilities';
import {
  adPlaybackStop,
  pause,
  playStation,
  resume,
  STATUSES,
} from '@/redux/actions/player';
import { RootState } from '@/redux/store';
import React, { Component, createRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import ControlsV2 from './ControlsV2';
import GamPreroll from './GamPreroll';

import { loadTdSk } from '../../utils/tdSdk';

const colorSet = {
  '--brand-primary': '#ba9951',
  '--brand-secondary': '#ffcb00',
  '--brand-tertiary': '#ffffff',
  '--brand-background-color': '#b1b1b1',
  '--brand-button-color': '#000000',
  '--brand-text-color': '#ffffff',
  '--brand-sponsorship-text-color': '#000000',
  '--brand-header-background': '#202020',
  '--brand-header-navigation-drop-down-background': '#313131',
  '--brand-header-icons-color': '#000000',
  '--brand-header-navigation-link-color': '#ed3123',
  '--brand-header-search-color': '#ffffff',
  '--brand-header-search-text-color': '#ffffff',
  '--brand-header-login-color': '#ffffff',
  '--brand-header-hamburger-menu-color': '#ed3123',
  '--brand-breaking-news-bar-text-color': '#ed3123',
  '--brand-breaking-news-bar-background-color': '#282828',
  '--brand-music-control-color': '#ffe964',
  '--global-theme-primary': '#1a1a1a',
  '--global-theme-secondary': '#282828',
  '--global-theme-font-primary': 'var(--global-white)',
  '--global-theme-font-secondary': '#717171',
  '--global-theme-font-tertiary': 'var(--global-dove-gray)',
  '--global-theme-footer-image':
    'url(&apos;https://wmmr.beasley-bbgi-stage.vipdev.lndo.site/wp-content/themes/experience-engine-v2/assets/images/beasley-dark-logo-cropped.png&apos;)',
};

// Define prop types for the component
type FeatureLayoutPlayerButtonProps = ConnectedProps<typeof connector> & {
  station?: string;
  inDropDown?: boolean;
  customTitle?: string | React.ReactNode;
};

type FeatureLayoutPlayerButtonState = {
  online: boolean;
  forceSpinner: boolean;
};

class FeatureLayoutPlayerButton extends Component<
  FeatureLayoutPlayerButtonProps,
  FeatureLayoutPlayerButtonState
> {
  private gamPrerollRef = createRef<GamPreroll>();

  container: HTMLElement | null = null;

  constructor(props: any) {
    super(props);
    this.state = { online: false, forceSpinner: false };
  }

  componentDidMount() {
    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  }

  handleOnline = () => {
    this.setState({ online: true });
  };

  handleOffline = () => {
    this.setState({ online: false });
  };

  /**
   * Handle cliks on the player play button. Those cliks will start the livestreaming
   * if there isn't anything playing.
   */
  handlePlay = () => {
    console.log({ here: true });
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { station, playStation }: any = this.props;
    if (station) {
      loadTdSk()
        .then(() => {
          playStation(station || '');
        })
        .catch(() => {});

      this.setState({ forceSpinner: true });
    }
  };

  turnOffForcedSpinner = () => {
    this.setState({ forceSpinner: false });
  };

  componentDidUpdate(prevProps: any) {
    const { gamAdPlayback, gamAdPlaybackStop, status }: any = this.props;
    // eslint-disable-next-line no-console
    console.log(
      `Player Button Updated: Current gamAdPlayback: ${
        gamAdPlayback ? 'true' : 'false'
      }, Previous gamAdPlayback: ${
        prevProps.gamAdPlayback ? 'true' : 'false'
      }, gamAdPlaybackStop: ${gamAdPlaybackStop ? 'true' : 'false'},  ${status}`
    );

    if (gamAdPlayback && !this.gamPrerollRef.current) {
      // pause();
      this.setState({ forceSpinner: true });
      // eslint-disable-next-line no-console
      console.log(
        `Player Button Updated: Current gamAdPlayback: ${
          gamAdPlayback ? 'true' : 'false'
        }, Previous gamAdPlayback: ${
          prevProps.gamAdPlayback ? 'true' : 'false'
        }, gamAdPlaybackStop: ${
          gamAdPlaybackStop ? 'true' : 'false'
        },  ${status}`
      );
    }

    if (this.state.forceSpinner && status === STATUSES.LIVE_CONNECTING) {
      // eslint-disable-next-line no-console
      console.log('status === STATUSES.LIVE_CONNECTING');
      this.turnOffForcedSpinner();
    } else if (gamAdPlayback && this.gamPrerollRef.current) {
      // eslint-disable-next-line no-console
      console.log('gamAdPlayback && this.gamPrerollRef.current');
      this.gamPrerollRef.current.doPreroll();
    } else if (gamAdPlaybackStop && this.state.forceSpinner) {
      // eslint-disable-next-line no-console
      console.log('gamAdPlaybackStop && this.state.forceSpinner');
      // eslint-disable-next-line no-console
      console.log('Player Button Triggering GamPreroll Finalize');
      this.turnOffForcedSpinner();
    }
  }

  render() {
    const { forceSpinner }: any = this.state;

    const {
      status,
      adPlayback,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      pause,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      resume,
      duration,
      player,
      playerType,
      inDropDown,
      customTitle,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      adPlaybackStop,
    }: any = this.props;

    const renderStatus =
      forceSpinner || adPlayback ? STATUSES.LIVE_CONNECTING : status;

    const progressClass = !duration ? '-live' : '-podcast';

    const controlsStyle: React.CSSProperties = {};
    const buttonsStyle: React.CSSProperties = {};
    const svgStyle: React.CSSProperties = {};
    const textStyle: React.CSSProperties = {};

    if (colorSet) {
      const colors = colorSet;
      controlsStyle.backgroundColor = 'transparent';
      buttonsStyle.backgroundColor =
        colors['--brand-button-color'] || colors['--global-theme-secondary'];
      buttonsStyle.border = '0';
      svgStyle.fill =
        colors['--brand-text-color'] || colors['--global-theme-secondary'];
      textStyle.color =
        colors['--brand-text-color'] || colors['--global-theme-secondary'];
    }

    const isIos = isIOS();
    const gamPreroll = forceSpinner ? (
      <GamPreroll
        ref={this.gamPrerollRef}
        adPlaybackStop={adPlaybackStop}
        gamTunerPreroll={this.props.gamTunerPreroll}
      />
    ) : null;

    const buttonDiv = (
      <div className="controls" style={controlsStyle}>
        <div className={`button-holder ${progressClass}`}>
          <ControlsV2
            status={renderStatus}
            play={() => {
              console.log({ playClicked: true });

              if (!adPlayback || !isAudioAdOnly({ player, playerType })) {
                this.handlePlay();
              }
              // adPlayback && isAudioAdOnly({ player, playerType })
              //   ? undefined
              //   : this.handlePlay();
            }}
            pause={pause}
            resume={resume}
            buttonStyle={buttonsStyle}
            svgStyle={svgStyle}
            isIos={isIos}
            customTitle={customTitle}
          />
        </div>
      </div>
    );

    if (inDropDown) {
      return (
        <ErrorBoundary>
          {gamPreroll}
          {buttonDiv}
        </ErrorBoundary>
      );
    }

    return null;
  }
}

// Map Redux state to component props
const mapStateToProps = ({ player, screen }: RootState) => ({
  player: player.player,
  playerType: player.playerType,
  station: player.station,
  status: player.status,
  adPlayback: player.adPlayback,
  gamAdPlayback: player.gamAdPlayback,
  gamAdPlaybackStop: player.gamAdPlaybackStop,
  duration: player.duration,
  gamTunerPreroll: screen.gamTunerPreroll,
});

// Map Redux actions to component props
const mapDispatchToProps = {
  playStation,
  pause,
  resume,
  adPlaybackStop,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(FeatureLayoutPlayerButton);
