export function getStorage(namespace: string) {
  return {
    /**
     * Retrieves an item from localStorage within the specified namespace.
     *
     * @param key - The key of the item to retrieve.
     * @returns The value associated with the key, or `null` if not found.
     */
    getItem(key: string): string | null {
      if (typeof window !== 'undefined') {
        return (window as any).localStorage.getItem(`${namespace}:${key}`);
      }
      return null;
    },

    /**
     * Stores an item in localStorage under the specified namespace.
     *
     * @param key - The key of the item to store.
     * @param value - The value to store.
     */
    setItem(key: string, value: any): void {
      (window as any).localStorage.setItem(`${namespace}:${key}`, value);
    },
  };
}

export default {
  getStorage,
};
