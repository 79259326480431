/**
 * Returns a parsed number from 0 to 100
 *
 * @param {Number} value - default 50
 * @returns {Number} volume
 */
export default function parseVolume(value: any = 50): number {
  let volume = parseInt(value, 10);
  if (Number.isNaN(volume) || volume > 100) {
    volume = 100;
  } else if (volume < 10) {
    volume = 10;
  }
  return volume;
}
