import React from 'react';

interface ControlsProps {
  status: string;
  title?: string;
  play?: () => void;
  pause?: () => void;
  resume?: () => void;
  colors?: React.CSSProperties;
  isIos?: boolean;
  progressClass?: string;
}

const Controls: React.FC<ControlsProps> = ({
  status,
  title = '',
  play = () => {},
  pause = () => {},
  resume = () => {},
  colors = {},
  isIos = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  progressClass = '',
}) => {
  // OS-specific class logic
  const osClass = isIos ? '-is-ios' : '';

  return (
    <div className={`status ${status} ${osClass}`}>
      <button
        type="button"
        className="play-btn"
        onClick={play}
        aria-label={`Play ${title}`}
        style={colors}
      >
        <svg viewBox="-2 0 17 25" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.1836 12.0055L0.910156 23.124L0.910156 0.887031L16.1836 12.0055Z" />
        </svg>
      </button>

      <button
        type="button"
        className="pause-btn"
        onClick={pause}
        aria-label="Pause"
        style={colors}
      >
        <svg
          width="13"
          height="23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="4" height="23" rx="1" fill="#fff" />
          <rect x="9" width="4" height="23" rx="1" fill="#fff" />
        </svg>
      </button>

      <button
        type="button"
        className="resume-btn"
        onClick={resume}
        aria-label="Resume"
        style={colors}
      >
        <svg viewBox="-2 0 17 25" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.1836 12.0055L0.910156 23.124L0.910156 0.887031L16.1836 12.0055Z" />
        </svg>
      </button>

      <button
        type="button"
        className="loading-btn"
        aria-label="Loading"
        style={colors}
      >
        <div className="loading" />
      </button>
    </div>
  );
};

export default Controls;
