import { ListItem } from '@mui/material';
import { SocialItem, SocialType } from '@orgnc/core/components/atoms';
import { ISocialItem } from '@orgnc/core/lib/wordpress/api/adapters/layoutDataAdapter';
import { Fragment, ReactNode } from 'react';

export const SocialMediaBlock = ({
  networks,
  children,
}: {
  networks: ISocialItem[];
  children?: ReactNode;
}) => {
  return (
    <Fragment>
      {networks
        .filter(({ icon, url }) => icon && url)
        .map((social: ISocialItem, index: number) => (
          <ListItem key={`header_social_link_${index}`}>
            <SocialItem
              sx={{ padding: 0, margin: 0 }}
              variant="square"
              type={social.icon as SocialType}
              href={(social.url as string) || '#'}
            />
          </ListItem>
        ))}
      {children}
    </Fragment>
  );
};
