export const isWhiz = (): boolean => {
  let whizDetected: boolean | null = null;

  if (typeof navigator !== 'undefined' && whizDetected === null) {
    // Check if 'whiz' exists in the User-Agent string
    if (navigator.userAgent) {
      whizDetected = navigator.userAgent.toLowerCase().includes('whiz');
    } else {
      whizDetected = false;
    }
  }

  // Allow toggling 'whiz' detection through URL query string
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('whiz')) {
      whizDetected = true;
    }
  }

  return !!whizDetected; // Ensure the return value is a boolean
};
