import DocumentHead from '@/components/DocumentHead';
import createTheme from '@orgnc/core/lib/themes/createTheme';
import getConfig from 'next/config';

import Footer from '@/components/Footer';
// import Header from '@/components/Header';
import HtlAd from '@/components/blocks/htl-ad/htl-ad';
import ArticleTemplate from '@/components/templates/Article/ArticleTemplate';
import bmgSettingsQuery from '@/lib/queries/bmgSettingsQuery';
import HtlAdBlockFragment from '@/lib/queries/htlAdBlockFragment';
import htlSettingsQuery from '@/lib/queries/htlSettingsQuery';
import podcastSectionFragment from '@/lib/queries/podcastSectionFragment';
import elementsTheme from '@orgnc/core/components/themes/Default';
import ITheme from '@orgnc/core/lib/themes/ITheme';
import { getArticleTemplateByName } from '@orgnc/core/lib/themes/templates/article';
import getArticleTemplate from '@orgnc/core/lib/themes/templates/functions/getArticleTemplate';
import getCategoryTemplate from '@orgnc/core/lib/themes/templates/functions/getCategoryTemplate';
import { GetPageTemplate } from '@orgnc/core/lib/themes/templates/functions/getPageTemplate';
import { getPageTemplateByName } from '@orgnc/core/lib/themes/templates/page';
import { defaultThemeSettings } from '@orgnc/core/lib/wordpress/api/adapters/themeSettingsAdapter';
import { composeEndpointUri } from '@orgnc/core/lib/wordpress/api/gqlFetcher';
import postCommentsQuery from '@orgnc/core/lib/wordpress/api/queries/postCommentsQuery';
import GlobalScripts from './components/GlobalScripts';
import PodcastSection from './components/PodcastSection';
import AudienceEmbedBlock from './components/blocks/audience-embed/audience-embed-block';
import CoreGroupBlock from './components/blocks/core-group/core-group-block';
import CoreImageBlock from './components/blocks/core-image/core-image-block';
import FacebookEmbedBlock from './components/blocks/facebook-embed/facebook-embed-block';
import STNEmbedBlock from './components/blocks/stn-embed/stn-embed';
import VoteEIBlock from './components/blocks/vote-ei/vote-ei';
import Header from './components/header/header';
import ContestsPostTemplate from './components/templates/Article/ContestsPostTemplate';
import CategoryTemplate from './components/templates/Category/CategoryTemplate';
import HomePageTemplate from './components/templates/Page/HomePageTemplate';
import PageTemplate from './components/templates/Page/PageTemplate';
import extendSettingsFragment from './lib/queries/extendSettingsFragment';
import getStreams from './lib/queries/getStreams';
import { getMuiTheme } from './themes';
import { SiteType } from './themes/type';

const DEFAULT_TEMPLATE_NAME = 'default';

const THEME_ID = getConfig().publicRuntimeConfig.themeId as SiteType;

// Headless site theme configuration.

const getClientConfig = (nextConfig: any, themeId: string) => {
  const containerAPIDomain = nextConfig.serverRuntimeConfig.wpDomain;
  const publicDefaultAPIDomain = nextConfig.publicRuntimeConfig.wpDomain;
  const publicAPIMultiDomain =
    nextConfig.publicRuntimeConfig.wpMultitenantDomain;
  const publicApiDomain =
    publicAPIMultiDomain[themeId] ?? publicDefaultAPIDomain;

  if (!publicAPIMultiDomain) {
    // Use default behavior based on env variables
    return undefined;
  }

  if (containerAPIDomain) {
    return {
      apiDomain: composeEndpointUri(containerAPIDomain, 'http'),
      wpPublicDomain: publicApiDomain,
    };
  }

  return {
    apiDomain: composeEndpointUri(publicApiDomain, 'https'),
    wpPublicDomain: publicApiDomain,
  };
};

export const getTheme = (themeId: string): ITheme => {
  const nextConfig = getConfig();
  return createTheme({
    ...elementsTheme,
    // It allows to specify the 'Head' component to use for the Next.js application's document structure.
    _document: {
      Head: DocumentHead,
    },
    Header,
    Footer,

    // Adds a custom Material-UI theme configuration.
    // This ensures that Material-UI components will use the custom styles
    // and configurations specified in muiTheme.
    muiTheme: getMuiTheme(themeId),
    graphqlClient: getClientConfig(nextConfig, themeId),
    redisCache: {
      keyPrefix:
        nextConfig.serverRuntimeConfig?.multitenantRedisKeyPrefix?.[themeId] ??
        nextConfig.serverRuntimeConfig?.redisKeyPrefix,
    },
    Global: GlobalScripts,
    extend: {
      fragments: {
        settings: extendSettingsFragment,
      },
      blocks: [
        {
          name: 'refact/fb-embed',
          component: FacebookEmbedBlock,
        },
        {
          name: 'refact/audience-embed',
          component: AudienceEmbedBlock,
        },
        {
          name: 'acf/htl-ad',
          component: HtlAd,
          fragment: HtlAdBlockFragment,
        },
        {
          name: 'create-block/bmg-general-blocks',
          component: PodcastSection,
          fragment: podcastSectionFragment,
        },
        {
          name: 'acf/stn-player',
          component: STNEmbedBlock,
        },
        {
          name: 'acf/vote-ei-block',
          component: VoteEIBlock,
        },
        {
          name: 'core/image',
          component: CoreImageBlock,
        },
        {
          name: 'core/group',
          component: CoreGroupBlock,
        },
      ],
      queries: {
        global: [htlSettingsQuery, bmgSettingsQuery, getStreams],
      },
    },
    pageTypes: {
      // Default to the base page types
      ...elementsTheme.pageTypes,

      // But instead of full width, use the "small" article template variant
      article: {
        templates: [
          {
            name: DEFAULT_TEMPLATE_NAME,
            component: ArticleTemplate,
            options: {
              variant: 'small',
            },
            queries: [postCommentsQuery],
          },
          {
            name: 'contests',
            component: ContestsPostTemplate,
            options: {
              variant: 'small',
            },
          },
        ],
        getTemplate: (article) => {
          if (
            article.categories.filter((item) => item.slug === 'contests').length
          ) {
            return getArticleTemplateByName(theme, 'contests');
          }
          return getArticleTemplate(article, theme);
        },
      },

      category: {
        templates: [
          {
            name: DEFAULT_TEMPLATE_NAME,
            component: CategoryTemplate,
          },
        ],
        getTemplate: (category) => {
          return getCategoryTemplate(category, defaultThemeSettings, theme);
        },
      },

      // And use the custom, simplified template for the root URL ("/")
      page: {
        templates: [
          { name: 'default', component: PageTemplate },
          { name: 'Homepage', component: HomePageTemplate },
        ],
        getTemplate: ((page) => {
          if (page.uri === '/') return getPageTemplateByName(theme, 'Homepage');
          return getPageTemplateByName(theme, DEFAULT_TEMPLATE_NAME);
        }) as GetPageTemplate,
      },
    },
  });
};

const theme = getTheme(THEME_ID);

export default theme;
